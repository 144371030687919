import { useCallback, useEffect, useMemo, useState } from "react";
import { useDispatch } from "react-redux";

import {
  doubleArrowLeft,
  doubleArrowRight,
  maximizeIcon,
  minimizedIcon,
  singleArrowLeft,
  singleArrowRight,
} from "../../../svgs";

import Flex from "../../flex";
import { Input } from "../../inputs";

import LinkButton from "../../linkbutton";
import { SelectDropdown } from "../../selectdropdowns";

import { AgGridLocalPaginationWithSelectProps } from "./aggridlocalpaginationwithselect.types";
import { AgGridServerSidePaginationWithSelectStyled } from "../aggridserversidepaginationwithselect";

import { numberFormatter } from "../../../utils";
import Checkbox from "../../checkbox";
import { useCustomPolling, useGetAppState } from "../../../customhooks";

import { setExpandableGridProps } from "../../../reducers/appreducer/appreducer";
import ConditionalDisplay from "../../conditionaldisplay";

const collapseGridIcon = minimizedIcon("12", "12");
const expandGridIcon = maximizeIcon("12", "12");

const AgGridLocalPaginationWithSelect = (
  props: AgGridLocalPaginationWithSelectProps
): JSX.Element => {
  const dispatch = useDispatch();

  const {
    gridId = "",
    selectionState,
    onClear,
    hiddenRecordsState,
    showOnlyCountPagination = false,
  } = props;

  const { isSelectAll = false, selectedRecords = 0 } = selectionState || {};

  const {
    customHiddenRecordText,
    onHiddenRecordCheckboxChange,
    hiddenRecordsSelected,
  } = hiddenRecordsState || {};

  const {
    expandableGridProps: { isGridExpanded = false } = { isGridExpanded: false },
  } = useGetAppState();

  const onToggleGridView = useCallback(() => {
    dispatch(
      setExpandableGridProps({
        isGridExpanded: !isGridExpanded,
        gridId: isGridExpanded ? "" : gridId,
      })
    );
  }, [isGridExpanded, gridId]);

  const { gridApi, showExpandCollapse = true } = props;

  const [, setTriggerState] = useState(new Date());
  const [paginationRowCount, setPaginationRowCount] = useState<number>(0);

  const pageSize = gridApi?.paginationGetPageSize();
  const currentPage = gridApi?.paginationGetCurrentPage() + 1;
  const totalPages = gridApi?.paginationGetTotalPages();

  const [pageNumer, setPageNumer] = useState<number>(1);

  const changeState = useCallback(() => {
    setTriggerState(new Date());
  }, []);

  const onInnerPageSizeChanged = useCallback(
    (innerPageSize) => {
      changeState();
      gridApi?.paginationSetPageSize(Number(innerPageSize));
    },
    [gridApi]
  );

  const changePage = useCallback((page) => {
    changeState();

    if (!Number.isNaN(Number(page))) {
      setPageNumer(Number(page));
    } else {
      setPageNumer(1);
    }
  }, []);

  const onEnter = useCallback((): void => {
    changeState();

    const updatedPageNumber = pageNumer > totalPages ? totalPages : pageNumer;
    setPageNumer(updatedPageNumber);

    gridApi?.paginationGoToPage(updatedPageNumber - 1);
  }, [pageNumer]);

  const onPageNavBtnHandler = useCallback(
    (pageNavType) => {
      changeState();

      switch (pageNavType) {
        case "first":
          gridApi?.paginationGoToFirstPage();
          break;
        case "last":
          gridApi?.paginationGoToLastPage();
          break;
        case "next":
          gridApi?.paginationGoToNextPage();
          break;
        case "prev":
          gridApi?.paginationGoToPreviousPage();
          break;
        default:
          break;
      }
    },
    [gridApi]
  );

  const startsFrom: number = useMemo(() => {
    if (currentPage === 1 && !paginationRowCount) {
      return 0;
    }

    return (currentPage - 1) * pageSize + 1;
  }, [currentPage, pageSize, paginationRowCount]);

  const isFirstPage = useMemo(() => {
    return currentPage <= 1;
  }, [currentPage]);

  const isLastPage = useMemo(() => {
    return currentPage >= totalPages;
  }, [currentPage, totalPages]);

  const displayedRowsCount = useMemo(() => {
    if (currentPage === 1 && !paginationRowCount) {
      return 0;
    }

    const count = currentPage * pageSize;

    if (count > gridApi?.paginationGetRowCount()) {
      return gridApi?.paginationGetRowCount() || count;
    }

    return count;
  }, [gridApi, currentPage, pageSize, paginationRowCount]);

  useEffect(() => {
    setPageNumer(paginationRowCount ? currentPage : 0);
  }, [currentPage, paginationRowCount]);

  const isNoRecordSelected: boolean = !selectedRecords && !isSelectAll;

  useCustomPolling(
    () => {
      if (gridApi)
        setPaginationRowCount(
          showOnlyCountPagination
            ? gridApi?.getDisplayedRowCount() || 0
            : gridApi?.paginationGetRowCount() || 0
        );
    },
    500,
    [gridApi]
  );

  return (
    <AgGridServerSidePaginationWithSelectStyled
      isNoRecordSelected={isNoRecordSelected}
    >
      <Flex>
        <div className="left">
          {isNoRecordSelected ? (
            <>
              Total records{" "}
              <span className="records-count-number">
                {numberFormatter(`${paginationRowCount || 0}`)}
              </span>
              {!!customHiddenRecordText && (
                <span className="hidden-records-checkbox">
                  <Checkbox
                    checked={hiddenRecordsSelected}
                    onChange={onHiddenRecordCheckboxChange}
                  />
                  {customHiddenRecordText}
                </span>
              )}
            </>
          ) : (
            <div className="selected-records-count-panel">
              <div>
                <span className="records-count-number">{selectedRecords}</span>
                {isSelectAll
                  ? " records in this view are selected"
                  : " record(s) selected"}
              </div>
              <LinkButton isTextUnderLine onClick={onClear}>
                Clear
              </LinkButton>
            </div>
          )}
        </div>

        <div className="right-wrapper">
          <div className="right">
            {!showOnlyCountPagination && (
              <>
                <div className="ag-paging-number">
                  <SelectDropdown
                    value={`${pageSize} records per page`}
                    onChange={onInnerPageSizeChanged}
                    height="25px"
                    options={["5", "10", "20", "50", "100"].map(
                      (op: string): any => ({
                        label: `${op} records per page`,
                        value: op,
                      })
                    )}
                  />
                </div>

                <div className="ag-paging-row-summary-panel" aria-hidden="true">
                  <div className="ag-paging-row-summary-panel-number">
                    {startsFrom}
                  </div>
                  <div>-</div>
                  <div className="ag-paging-row-summary-panel-number">
                    {displayedRowsCount}
                  </div>
                  <div>of</div>
                  <div className="ag-paging-row-summary-panel-number">
                    {numberFormatter(`${paginationRowCount || 0}`)}
                  </div>
                </div>
              </>
            )}

            <div className="ag-paging-page-summary-panel">
              {!showOnlyCountPagination && (
                <>
                  {/* Previous arrows */}
                  <LinkButton
                    aria-label="First Page"
                    className={`ag-paging-button first-btn${
                      isFirstPage ? " ag-disabled" : ""
                    }`}
                    onClick={(): void => onPageNavBtnHandler("first")}
                  >
                    {doubleArrowLeft("11px", "12px")}
                  </LinkButton>
                  <LinkButton
                    className={`ag-paging-button prev-btn${
                      isFirstPage ? " ag-disabled" : ""
                    }`}
                    aria-label="Previous Page"
                    onClick={(): void => onPageNavBtnHandler("prev")}
                  >
                    {singleArrowLeft("7px", "12px")}
                  </LinkButton>

                  {/* Current page number */}
                  <div className="ag-paging-description" aria-hidden="true">
                    <div>Page</div>
                    <div className="ag-paging-number">
                      <Input
                        type="text"
                        value={pageNumer}
                        onChange={(e): void => changePage(e?.target?.value)}
                        onPressEnter={onEnter}
                      />
                    </div>
                    <div>of</div>
                    <div>{numberFormatter(`${totalPages || 0}`)}</div>
                  </div>

                  {/* Next arrows */}
                  <LinkButton
                    className={`ag-paging-button next-btn${
                      isLastPage ? " ag-disabled" : ""
                    }`}
                    aria-label="Next Page"
                    onClick={(): void => onPageNavBtnHandler("next")}
                  >
                    {singleArrowRight("7px", "12px")}
                  </LinkButton>
                  <LinkButton
                    className={`ag-paging-button last-btn${
                      isLastPage ? " ag-disabled" : ""
                    }`}
                    aria-label="Last Page"
                    onClick={(): void => onPageNavBtnHandler("last")}
                  >
                    {doubleArrowRight("11px", "12px")}
                  </LinkButton>
                </>
              )}

              <ConditionalDisplay condition={showExpandCollapse}>
                <LinkButton
                  className="ag-paging-button last-btn min-max-btn"
                  aria-label="Last Page"
                  onClick={onToggleGridView}
                  tooltipProps={{
                    title: isGridExpanded ? "Collapse Table" : "Expand Table",
                    placement: "topRight",
                  }}
                >
                  {isGridExpanded ? collapseGridIcon : expandGridIcon}
                </LinkButton>
              </ConditionalDisplay>
            </div>
          </div>
        </div>
      </Flex>
    </AgGridServerSidePaginationWithSelectStyled>
  );
};

export default AgGridLocalPaginationWithSelect;
