import { CheckboxProps } from "../../pages/sourcedetailpage/tabs/tabs.components/scannowwrapper/scannowwrapper.types";
import { SourceAdhocInfoType } from "../../parsers/sources/sourcesparser.types";
import { getDvSumInformativeText } from "../../utils";

export const ALL_SCAN_OPTIONS = [
  { label: "Profile", value: "PRF" },
  { label: "Lineage", value: "LIN" },
];

export const getAlreadyCheckedScanOptions = (
  currentSrcAdhocInfo: SourceAdhocInfoType
): string[] => {
  if ("scan_options_checked" in (currentSrcAdhocInfo || {})) {
    const checkScanOptions = currentSrcAdhocInfo?.scan_options_checked || [];
    return checkScanOptions || [];
  }
  return [];
};

export const getAvailableScanTypeOptions = (
  currentSrcAdhocInfo: SourceAdhocInfoType
): CheckboxProps[] => {
  if ("scan_options_allowed" in (currentSrcAdhocInfo || {})) {
    const scanOptions = currentSrcAdhocInfo?.scan_options_allowed || [];
    const filteredScanOptions = scanOptions?.filter((id) => id !== "CTG");

    const finalOptions = filteredScanOptions?.map((id) => {
      const option = ALL_SCAN_OPTIONS?.filter((opt) => opt?.value === id);

      const scanOptionsAlreadyCheck = getAlreadyCheckedScanOptions(
        currentSrcAdhocInfo
      );

      const checkboxOption = option?.[0];
      const isScanOptionsAlreadyCheck = scanOptionsAlreadyCheck?.includes(
        checkboxOption?.value
      );

      const finalOption = {
        ...checkboxOption,
        checked: isScanOptionsAlreadyCheck,
        disabled: isScanOptionsAlreadyCheck,
      };
      return finalOption;
    });

    return finalOptions;
  }

  return ALL_SCAN_OPTIONS;
};

export const getScanDescription = (
  currentSrcAdhocInfo: SourceAdhocInfoType
): string => {
  const {
    src_scan_hist_full_scan: srcScanHistFullScan,
    src_scan_hist_ctg_lin_scan: srcScanHistCtgLinScan,
    src_scan_hist_ctg_prf_scan: srcScanHistCtgPrfScan,
  } = getDvSumInformativeText();

  const { description: srcScanHistFullScanDesc = "" } =
    srcScanHistFullScan || {};

  const { description: srcScanHistCtgLinScanDesc = "" } =
    srcScanHistCtgLinScan || {};

  const { description: srcScanHistCtgPrfScanDesc = "" } =
    srcScanHistCtgPrfScan || {};

  if ("scan_options_allowed" in (currentSrcAdhocInfo || {})) {
    const scanOptions = currentSrcAdhocInfo?.scan_options_allowed || [];

    if (scanOptions?.includes("PRF")) return srcScanHistCtgPrfScanDesc;
    if (scanOptions?.includes("LIN")) return srcScanHistCtgLinScanDesc;
  }
  return srcScanHistFullScanDesc;
};
