import styled from "styled-components";

export const ETLRefDrawerStyled = styled.div``;

export const ETLRefDrawerHeaderStyled = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  row-gap: 10px;
  flex-direction: column;
  border-bottom: 1px solid
    ${({ theme }): string => theme.borderColors.LIGHT_GREY_51};
  padding: 15px 20px;

  .etl-info {
    display: flex;
    align-items: center;
    column-gap: 8px;
    color: ${({ theme }): string => theme.textColors.BLUE_116};
    font-family: OpenSansSemiBold;
    font-size: 19.2px;

    .etl-icon {
      svg {
        padding-top: 7px;

        path {
          fill: ${({ theme }): string => theme?.textColors?.BLUE_116};
        }
      }
    }
  }
`;

export const ETLRefDrawerSectionWrapperStyled = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  row-gap: 10px;
  flex-direction: column;

  .etl-metadata-info,
  .etl-exec-details-info,
  .etl-managed-by-info,
  .etl-code-info {
    padding: 10px 20px;
    border-bottom: 1px solid
      ${({ theme }): string => theme.borderColors.LIGHT_GREY_51};
    width: 100%;

    .etl-node-icon {
      height: 20px;
      svg {
        path {
          fill: ${({ theme }): string => theme?.textColors?.BLUE_116};
        }
      }
    }
  }

  .etl-code-info {
    .query-wrapper {
      max-height: calc(100vh - 415px);
      min-height: calc(100vh - 415px);
    }
  }
`;

export const StatusRendererStyled = styled.div`
  display: flex;
  column-gap: 5px;
  align-items: center;

  .passed {
    svg {
      path {
        fill: ${({ theme }): string => theme.textColors.GREEN_111};
      }
    }
  }
`;

export const MultipleNodesRendererStyled = styled.div`
  display: flex;
  align-items: flex-start;
  column-gap: 5px;
  flex-wrap: wrap;
  row-gap: 10px;

  .table-field-name {
    display: flex;
    align-items: center;
    gap: 6px;

    .letter-icon-mapping {
      font-size: 10px;
    }
  }
`;

export const EtlCodeRefDrawerHeaderWrapperStyled = styled.div`
  display: flex;
  align-items: center;
  column-gap: 8px;
  color: ${({ theme }): string => theme.textColors.BLUE_116};
  font-family: OpenSansSemiBold;
  font-size: 19.2px;
`;
