import { AxiosResponse } from "axios";
import { EtlDdetailsResponseType, EtlParsedDocType } from "./etlparser.types";
import { convertToHoursMinutesAndSeconds } from "../../utils";

export function getParsedEtlDocForRefPage({
  data,
}: AxiosResponse<EtlDdetailsResponseType>): EtlParsedDocType {
  const pipleLineDetails = data?.pipeline_details?.[0];

  const durationResp = pipleLineDetails?.pl_exec_duration_secs;
  const durationSecs = durationResp ? Number(durationResp) : 0;
  const duration = durationSecs
    ? convertToHoursMinutesAndSeconds(durationSecs)
    : "--";

  return {
    nodeName: data?.node_name || "",
    parentName: data?.parent_name || "",
    parentId: data?.parent_id || 0,
    sourceTypeId: data?.src_type_id || "",
    etlCodeS3Url: data?.plo_code_s3_url || "",
    etlCode: data?.plo_code || "",
    dbName: data?.db_name || "",
    schemaName: data?.folder_name || "",

    metaInfo: [
      {
        title: "Parent Node",
        value: data?.parent_plo_name || "",
      },
      {
        title: "Description",
        value: data?.description?.value || "",
      },

      {
        title: "Job Name",
        value: pipleLineDetails?.pl_name || "",
      },
      {
        title: "Job Type",
        value: pipleLineDetails?.pl_type || "",
      },
      {
        title: "Source",
        stringValues: data?.plo_sources?.map((item) => item?.node_name) || [],
        ids: data?.plo_targets?.map((item) => `${item?.node_id}`) || [],
      },
      {
        title: "Target",
        stringValues: data?.plo_targets?.map((item) => item?.node_name) || [],
        ids: data?.plo_targets?.map((item) => `${item?.node_id}`) || [],
      },
    ],
    execution: [
      {
        title: "Last Run",
        value: pipleLineDetails?.pl_last_exec_on || "",
      },

      {
        title: "Duration",
        value: duration,
      },
      {
        title: "Run Status",
        id: pipleLineDetails?.pl_last_exec_status || "",
      },
    ],
    managedBy:
      data?.audit_trail?.map((item) => {
        return {
          title: `${item?.activity} By`,
          value: item?.user_name,
        };
      }) || [],
  };
}
