import {
  getDataSourceDetailPageUrl,
  getSourceDetailPageUrl,
  getTableDetailPageUrl,
} from "../../utils";
import { BreadcrumItemStringType, ItemType } from "./styledbreadcrum.types";

export const getBreadCrumItemType = (
  item: BreadcrumItemStringType
): ItemType => {
  return {
    isSrcNameItem: item === "src",
    isSchemaNameItem: item === "schema",
    isTblNameItem: item === "tbl",
    isDbNameItem: item === "db",
    isTermDmnItem: item === "term-dmn",
    isTermCategoryItem: item === "term-category",
    isColItem: item === "col",
    isDsrItem: item === "dsr",
  };
};

export const getBreadcrumItemUrl = (
  typeOfBreadcrum: BreadcrumItemStringType,
  nodeId: string
): string => {
  const {
    isSrcNameItem,
    isSchemaNameItem,
    isTblNameItem,
    isDbNameItem,
    isTermDmnItem,
    isTermCategoryItem,
    isDsrItem,
  } = getBreadCrumItemType(typeOfBreadcrum);

  if (isSrcNameItem) {
    return getSourceDetailPageUrl(nodeId);
  }

  if (isTblNameItem) {
    return getTableDetailPageUrl(nodeId);
  }

  if (isDsrItem) {
    return getDataSourceDetailPageUrl(nodeId);
  }

  if (isTermCategoryItem || isTermDmnItem || isSchemaNameItem || isDbNameItem) {
    return "";
  }

  return "";
};

export const getBreadcrumItemClassName = (
  typeOfBreadcrum: BreadcrumItemStringType
): string => {
  const {
    isSrcNameItem,
    isSchemaNameItem,
    isTblNameItem,
    isDbNameItem,
    isTermDmnItem,
    isTermCategoryItem,
    isDsrItem,
  } = getBreadCrumItemType(typeOfBreadcrum);

  if (isSrcNameItem) {
    return "src-connection-name";
  }

  if (isTblNameItem) {
    return "tbl-name";
  }

  if (isDbNameItem) {
    return "db-name";
  }

  if (isSchemaNameItem) {
    return "schema-name";
  }

  if (isTermDmnItem) {
    return "term-dmn-name";
  }

  if (isTermCategoryItem) {
    return "term-category-name";
  }

  if (isDsrItem) {
    return "dsr-name";
  }

  return "";
};
