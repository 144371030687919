import { useCallback, useMemo } from "react";

import LinkButton from "../linkbutton";
import ListItemsView from "../listitemsview/listitemsview";

import PageHeader from "../pageheader";
import Flex from "../flex";

import {
  BoldTextStyled,
  GlossaryPageHeaderStyled,
  MoreActionStyled,
  RulePageLeftSectionStyled,
} from "./glossarypageheader.styles";

import {
  blackCloneIcon,
  cloneIcon,
  deleteIcon,
  deleteIcon1,
  duplicateIcon,
  iconChevronDownLarge,
  iconDownload,
  importIcon,
  multiChatFilledIcon,
  saveIcon,
  shareIconWithoutBorder,
  toReviewFilledSvg,
  widgetIcon,
} from "../../svgs";

import { GlossaryPageHeaderProps } from "./glossarypageheader.types";
import { ELEMENT_IDS } from "../../constants";

import { useGetAppState, useOpenModal } from "../../customhooks";
import MultiLevelDropDownMenu from "../multileveldropdownmenu";

import { useGetRefByType } from "../../api/refservice";
import { getParsedDqRulesCategoriesAndTypes } from "./glossarypageheader.util";

import { VerticalDividerStyled } from "../dividers/dividers.styles";
import {
  getUserPermissions,
  isJsonString,
  jsonParse,
  getPageNode,
} from "../../utils";

import { ViewSelector, ThreeDotActionMenu } from "..";
import { ViewSelectorOptionsType } from "../viewselector/viewselector.types";

import ConfirmationPopover from "../confirmationpopover/confirmationpopover";
import { useRequestWithMethod } from "../../api";
import { peopleCountBlankSlate } from "../../blankslates/listingpagesblankslate";
import { ShareListResponse } from "../../parsers/socialnode/socialnodeparser.types";

const {
  allcat_lst_lst_export_btn: ALLCAT_LST_LST_EXPORT_BTN,
  allcat_lst_lst_get_views_btn: ALLCAT_LST_LST_GET_VIEWS_BTN,
  allcat_lst_lst_view_ttl: ALLCAT_LST_LST_VIEW_TTL,
  allcat_lst_lst_share_btn: ALLCAT_LST_LST_SHARE_BTN,
  allcat_lst_lst_delete_btn: ALLCAT_LST_LST_DELETE_BTN,
  allcat_lst_lst_add_btn: ALLCAT_LST_LST_ADD_BTN,
  allcat_lst_lst_create_btn: ALLCAT_LST_LST_CREATE_BTN,
  cht_bot_anal_tab_cht_lst: CHT_BOT_ANAL_TAB_CHT_LST,
  cht_bot_anal_tab_fbk_lst: CHT_BOT_ANAL_TAB_FBK_LST,
} = ELEMENT_IDS;

const addWidgetIcon = widgetIcon("20px", "20px");

const ACCESS_OPTIONS = [
  { label: "Editor", value: "editor", key: "editor" },
  { label: "Viewer", value: "viewer", key: "viewer" },
];

function GlossaryPageHeader(props: GlossaryPageHeaderProps): JSX.Element {
  const {
    data,
    title,
    onClick,
    onClickCreateView,
    sectionTitle = "",
    isTitleStatic = false,
    hideRightSection = false,
    stateHandlerProps,
    download,
    dataImport,
    save,
    share,
    isDefaultView = false,
    isViewer = false,
    isAdmin = false,
    leftSecButtons = [],
    hideLeftSideActions,
    nodeType,
    nodeId = 0,
    createdById = 0,
    onChangeHeaderRightView,
    customRightSectionAction,
    isOutsideListingVews = false,
    tableId = "",
    sourceId = 0,
    srcTypeId,
    isOnlineProfilingInProgress = false,
    freshnessRuleColumnId = 0,
    isExpandedGridView = false,
    addWidget,
    deleteGovView,
    cloneGovView,
  } = props;

  const onOpenModal = useOpenModal();

  const {
    isJobPage,
    isRulePage,
    isJobExecutionsPage,
    isDashboardPage,
    isColumnPage,
    isAgentAnalyticsChatPage,
    isFeedbackPage,
  } = getPageNode(nodeType);

  const {
    is_catalog_admin: isCatalogAdmin = false,
    is_catalog_editor: isCatalogEditor = false,
  } = getUserPermissions();

  const canUserAddRule = isCatalogAdmin || isCatalogEditor;

  const { parsedData: dqRulesCategoriesAndTypes } = useGetRefByType(
    "DQ_RULE_TYPES",
    undefined,
    isRulePage
  );

  const {
    isLoading: loadingShareList,
    error: errorInShareList,
    onExecuteRequest: onGetShareList,
    data: shareListData,
  } = useRequestWithMethod("get_share_list", [nodeId, nodeType], true);

  const numberOfPeopleSharedWith =
    shareListData?.filter(
      (item: ShareListResponse) => item?.NODE_REL_TYPE !== "GOV_OWNER"
    )?.length || 0;

  const isSharedWithNoOne = numberOfPeopleSharedWith === 0;

  const getSharedMessage = (): string | JSX.Element => {
    if (loadingShareList) {
      return (
        <>
          {peopleCountBlankSlate}
          <br />
        </>
      );
    }

    if (isSharedWithNoOne) {
      return " no one.";
    }

    return (
      <>
        <BoldTextStyled>{numberOfPeopleSharedWith}</BoldTextStyled> people.
      </>
    );
  };

  const deleteDesc = (
    <p>
      You have shared this dashboard with
      {getSharedMessage()} Please note this action cannot be reversed. Are you
      sure?
    </p>
  );

  const { modal } = useGetAppState();

  const isProfilingInProgressAndRulePage =
    isRulePage && isOutsideListingVews && isOnlineProfilingInProgress;

  const isDqTabLisiting = isOutsideListingVews && isRulePage;

  const isFreshnessRuleDisabled = !freshnessRuleColumnId;

  const dqRules = useMemo(() => {
    return dqRulesCategoriesAndTypes?.map((rule) => {
      const { adhocInfo } = rule;

      const parsedAdhocInfo = isJsonString(adhocInfo || "")
        ? jsonParse(adhocInfo || "")
        : {};

      const nonSupportingSrcTypes =
        parsedAdhocInfo?.non_supporting_src_types || [];

      const isRuleDisabled =
        (rule?.id === "FRC" && isFreshnessRuleDisabled) ||
        nonSupportingSrcTypes?.includes(srcTypeId);

      return {
        ...rule,
        disabled: isRuleDisabled,
      };
    });
  }, [dqRulesCategoriesAndTypes, srcTypeId, isFreshnessRuleDisabled]);

  const parsedDqRules = getParsedDqRulesCategoriesAndTypes(dqRules);

  const renderLeftSectionButtons = useMemo(() => {
    return leftSecButtons?.map((button) => {
      const {
        isHide,
        disabled,
        icon,
        onClick,
        title,
        customClass,
        id = "",
        tooltipProps,
      } = button;
      return (
        !isHide && (
          <LinkButton
            className={customClass}
            onClick={onClick}
            title={title}
            disabled={disabled}
            elementId={id}
            tooltipProps={tooltipProps}
          >
            {icon}
          </LinkButton>
        )
      );
    });
  }, [leftSecButtons]);

  const chatViewSelectorOptions = useMemo(
    (): ViewSelectorOptionsType => [
      {
        id: "CHT",
        tooltipProps: { title: "Chats Analytics", placement: "topLeft" },
        iconOrLabelJsx: multiChatFilledIcon(),
        isActive: nodeType === "CHT",
        className: "chats-listing",
        onClick: onChangeHeaderRightView as ViewSelectorOptionsType[number]["onClick"],
        elementId: CHT_BOT_ANAL_TAB_CHT_LST,
      },
      {
        id: "FBK",
        tooltipProps: { title: "Feedback Analytics", placement: "topLeft" },
        iconOrLabelJsx: toReviewFilledSvg(),
        isActive: nodeType === "FBK",
        className: "feedback-analytics",
        onClick: onChangeHeaderRightView as ViewSelectorOptionsType[number]["onClick"],
        elementId: CHT_BOT_ANAL_TAB_FBK_LST,
      },
    ],
    [nodeType, onChangeHeaderRightView]
  );

  const staticGovViewName = <span className="node-static-text">{title} </span>;

  const govViewDropdown = (
    <ListItemsView
      data={data}
      sectionTitle={sectionTitle}
      selected={String(nodeId)}
      onClick={onClick}
      onClickCreateView={onClickCreateView}
      isViewer={isViewer}
      createViewElemId={ALLCAT_LST_LST_CREATE_BTN}
      nodeType={nodeType}
    >
      <LinkButton
        disabled={save?.isLoading || isOnlineProfilingInProgress}
        elementId={ALLCAT_LST_LST_GET_VIEWS_BTN}
      >
        <span id={ALLCAT_LST_LST_VIEW_TTL} className="gov-view-static-text">
          {title} {iconChevronDownLarge}
        </span>
      </LinkButton>
    </ListItemsView>
  );

  const rulesHeading = <div className="page-name">Rules</div>;

  const verticalDivider = <VerticalDividerStyled height="8px" />;

  const addRuleMenuDrpDwn = (
    <MultiLevelDropDownMenu
      data={parsedDqRules}
      tableId={tableId}
      sourceId={sourceId}
      srcTypeId={srcTypeId}
      isOnlineProfilingInProgress={isOnlineProfilingInProgress}
      freshnessRuleColumnId={freshnessRuleColumnId}
    />
  );

  const addRuleMenuWrapper = (
    <>
      {verticalDivider}
      {addRuleMenuDrpDwn}
    </>
  );

  const dqTabListingPageHeaderLeftSection = (
    <RulePageLeftSectionStyled>
      {rulesHeading}
      {canUserAddRule ? addRuleMenuWrapper : <div />}
    </RulePageLeftSectionStyled>
  );

  const govViewLeftButtonActionsWrapper = (
    <Flex columnGap={16} customClass="gph-action-buttons">
      {renderLeftSectionButtons}
    </Flex>
  );

  const renderPageHeaderLeftComponent = (): JSX.Element => {
    if (isExpandedGridView && isColumnPage && isOutsideListingVews)
      return <div />;
    if (isExpandedGridView && (isAgentAnalyticsChatPage || isFeedbackPage))
      return staticGovViewName;
    if (isExpandedGridView && !isDqTabLisiting) return staticGovViewName;
    if (!isDqTabLisiting)
      return (
        <>
          {govViewDropdown}
          {govViewLeftButtonActionsWrapper}
        </>
      );
    if (isDqTabLisiting)
      return (
        <>
          {govViewLeftButtonActionsWrapper}
          {dqTabListingPageHeaderLeftSection}
        </>
      );

    return <div />;
  };

  const pageHeaderLeftComponent = renderPageHeaderLeftComponent();

  const onHandleShare = useCallback(() => {
    onOpenModal({
      modalId: isAdmin ? "share_owner" : "share_viewer",
      visible: true,
      modalTitle: isAdmin ? "Share" : "",
      modalProps: {
        accessOptionsInInput: ACCESS_OPTIONS,
        accessOptionsInListing: [
          ...ACCESS_OPTIONS,
          {
            label: "Remove Access",
            value: "remove-access",
            key: "remove-access",
          },
        ],
        nodeId,
        nodeType: isDashboardPage ? "DSH" : "SRH",
        successMessage: "View shared successfully",
        createdById,
      },
    });
  }, [isAdmin, nodeId, createdById, nodeType]);

  const overLayItems = [
    // Clone Button (conditionally included)
    ...(isDashboardPage
      ? [
          {
            name: "Clone",
            icon: duplicateIcon("16px", "16px"),
            onClick: cloneGovView?.onClick,
            customClass: "clone-btn",
            disable:
              cloneGovView?.isLoading ||
              isViewer ||
              isOnlineProfilingInProgress,
            id: "glossary-header-clone-action",
          },
        ]
      : []),

    // Delete Button (conditionally included)
    ...(isDashboardPage && !deleteGovView?.isHidden
      ? [
          {
            name: "Delete",
            icon: deleteIcon1("16px", "16px"),
            onClick: deleteGovView?.onClick,
            customClass: "delete-btn",
            disable: deleteGovView?.isHidden,
            isDelete: true,
            handleDelete: deleteGovView?.onClick,
            tooltipTitle: "Delete View",
            id: "glossary-header-delete-action",
          },
        ]
      : []),
  ];

  const pageHeaderRightComponent =
    nodeType === "CHT" || nodeType === "FBK" ? (
      <div className="toggle-view">
        <ViewSelector
          label="View"
          viewSelectorOptions={chatViewSelectorOptions}
        />
      </div>
    ) : !hideRightSection && !isExpandedGridView ? (
      // THIS IS DOWNLOAD, IMPORT, SAVE AND SHARE BUTTONS ON LEFT SIDE OF LISTING, WILL BE HIDDEN OUTSIDE LISTING

      <>
        {isDashboardPage && !addWidget?.isHidden && (
          <LinkButton
            className="add-icon"
            onClick={addWidget?.onClick}
            disabled={addWidget?.isLoading}
            elementId={ALLCAT_LST_LST_ADD_BTN}
          >
            {addWidgetIcon} Add Widget
          </LinkButton>
        )}

        {!isDashboardPage && (
          <LinkButton
            className="download-icon"
            onClick={download?.onClick}
            disabled={download?.isLoading || isOnlineProfilingInProgress}
            elementId={modal?.visible ? "" : ALLCAT_LST_LST_EXPORT_BTN}
          >
            {iconDownload("16.24px", "16.7px")} Download
          </LinkButton>
        )}

        {!isRulePage && !isJobPage && !isJobExecutionsPage && !isDashboardPage && (
          <>
            <LinkButton
              className="import-icon"
              onClick={dataImport?.onClick}
              disabled={
                dataImport?.isDisabled ||
                isViewer ||
                isOnlineProfilingInProgress
              }
            >
              {importIcon("16.24px", "16.7px")} Import
            </LinkButton>

            {!isJobPage && !isJobExecutionsPage && !isDashboardPage && (
              <LinkButton
                className="save-icon"
                onClick={save?.onClick}
                disabled={
                  isDefaultView ||
                  save?.isLoading ||
                  isViewer ||
                  isOnlineProfilingInProgress
                }
              >
                {saveIcon("9.92px", "16.7px")} Save
                {save?.isFavourite && "d"}
              </LinkButton>
            )}
          </>
        )}

        {!share?.isHidden && (
          <LinkButton
            onClick={onHandleShare}
            disabled={
              isDefaultView ||
              share?.isLoading ||
              isViewer ||
              isOnlineProfilingInProgress
            }
            elementId={ALLCAT_LST_LST_SHARE_BTN}
            className="share-icon"
          >
            {shareIconWithoutBorder("16.8px", "21.54px")} Share
          </LinkButton>
        )}

        {overLayItems?.length > 0 && (
          <MoreActionStyled>
            <div className="more-actions">
              <ThreeDotActionMenu
                threeDotsMenuId="glossary-header-actions-menu"
                overLayItems={overLayItems}
              />
              More
            </div>
          </MoreActionStyled>
        )}

        {/* {isDashboardPage && (
          <LinkButton
            onClick={onCloneClick}
            disabled={
              isDefaultView ||
              cloneGovView?.isLoading ||
              isViewer ||
              isOnlineProfilingInProgress
            }
            elementId={ALLCAT_LST_LST_SHARE_BTN}
            className="clone-icon"
          >
            {blackCloneIcon()} Clone
          </LinkButton>
        )}

        {isDashboardPage && !deleteGovView?.isHidden && (
          <ConfirmationPopover
            onOk={onDeleteClick}
            okText="Delete"
            cancelText="Cancel"
            heading="Delete Dashboard"
            desc={deleteDesc}
            width="350px"
          >
            <LinkButton
              className="delete-icon"
              onClick={(): void => onGetShareList()}
            >
              {deleteIcon} Delete
            </LinkButton>
          </ConfirmationPopover>
        )} */}
      </>
    ) : (
      //  ADDING SEARCH BAR AND AVB/RCM SWITCH IN DQ LISTING TAB/SEARCH IN JOB DETAIL PAGE
      <>{!!customRightSectionAction && customRightSectionAction}</>
    );

  return (
    <GlossaryPageHeaderStyled
      isProfilingInProgress={isProfilingInProgressAndRulePage}
      isDqTabOutSideListing={!!isDqTabLisiting}
    >
      <PageHeader
        padding="0px"
        stateHandlerProps={stateHandlerProps}
        leftComponent={pageHeaderLeftComponent}
        rightComponent={pageHeaderRightComponent}
      />
    </GlossaryPageHeaderStyled>
  );
}

export default GlossaryPageHeader;
