import { useGetAppState } from "../../../../../../../../../../customhooks";

import {
  columnChartIcon,
  databaseTableIcon,
  gridWithSideBarIcon,
} from "../../../../../../../../../../svgs";

import { checkQueryType } from "../../../../../../../../analisisdetailpage.utils";
import { ResultsToggleIconContainerStyled } from "./querychartactions.styles";

import { QueryChartActionType } from "./querychartactions.types";

const QueryChartActions = (props: QueryChartActionType): JSX.Element => {
  const {
    expandableGridProps: { isGridExpanded = false },
  } = useGetAppState();

  const { onToggleResultsViewQueryBox, queryItem, showPivotOpt = true } = props;

  const { header, id = "", type, sub_type: subType, results } = queryItem || {};

  const { result_type: resultType = "grid" } = header || {};

  const {
    chart_config: chartConfig,
    is_caddi_sample_data: isCaddiSampleData = false,
  } = results || {};

  const { chart_type: chartType } = chartConfig || {};
  const isChartExists = chartType && chartType !== "N/A";

  const isChartView = resultType === "chart";
  const isGridView = resultType === "grid";
  const isPivotView = resultType === "pivot";

  const { isNoCodeQuery } = checkQueryType(type, subType);

  return (
    <>
      {!isGridExpanded && (
        <ResultsToggleIconContainerStyled
          isActive={isChartView}
          tooltipProps={{
            title: isCaddiSampleData
              ? "Chart doesn't exist on sample data."
              : isNoCodeQuery
              ? "Chart doesn't exist on No-Code query."
              : !isChartExists
              ? "Chart doesn't exist for this question."
              : "Chart view",
            placement: "topRight",
          }}
          onClick={(e): void => {
            onToggleResultsViewQueryBox?.(id, "chart", e, true, true);
          }}
          data-testid="analysis-detail-page-query-block-header-right-sec-chart-view-btn"
          disabled={isCaddiSampleData || !isChartExists || isNoCodeQuery}
        >
          {columnChartIcon("19.2", "15.5")}
        </ResultsToggleIconContainerStyled>
      )}

      <ResultsToggleIconContainerStyled
        isActive={isGridView}
        data-testid="analysis-detail-page-query-block-header-right-sec-grid-view-btn"
        tooltipProps={{
          title: "Table view",
          placement: "topRight",
        }}
        onClick={(e): void => {
          onToggleResultsViewQueryBox?.(id, "grid", e, true, true);
        }}
      >
        {databaseTableIcon("19.2", "15.5")}
      </ResultsToggleIconContainerStyled>

      {showPivotOpt && (
        <ResultsToggleIconContainerStyled
          isActive={isPivotView}
          data-testid="analysis-detail-page-query-block-header-right-sec-pivot-view-btn"
          tooltipProps={{
            title: "Pivot table view",
            placement: "topRight",
          }}
          onClick={(e): void => {
            onToggleResultsViewQueryBox?.(id, "pivot", e, true, true);
          }}
        >
          {gridWithSideBarIcon()}
        </ResultsToggleIconContainerStyled>
      )}
    </>
  );
};

export default QueryChartActions;
