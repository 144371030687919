import { useCallback, useMemo } from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";

import { Button } from "../../components";
import { FormItemStyled, FormStyled } from "../../components/form";
import { CheckboxField, SelectField } from "../../components/formfields";
import StateHandler from "../../components/statehandler/statehandler";
import SuccessNotificationMessage from "../../components/successnotificationmessagerendrer/successnotificationmessagerendrer";

import { useGetMassEditableFields } from "../../api/listingsservice";
import { useRequestWithMethod } from "../../api";

import { useCancelModal, useGetAppState } from "../../customhooks";

import { HandleSubmitType } from "../../app.types";
import {
  UpdateTagContentProps,
  UpdateTagsFormType,
  UpdateTagsModalProps,
  UpdateTagsProps,
} from "./updatetags.types";

import { openNotification } from "../../utils";
import { updateTagsSchema } from "../../utils/schemas";

import { UpdateTagsStyled } from "./updatetags.styles";
import { infoIcon } from "../../svgs";
import IconWithText from "../../components/iconwithtext";
import { ELEMENT_IDS } from "../../constants";

const {
  allcat_lst_lst_upd_tag_apply_btn: ALLCAT_LST_LST_UPD_TAG_APPLY_BTN,
  allcat_lst_lst_upd_tag_tag_fld: ALLCAT_LST_LST_UPD_TAG_TAG_FLD,
  allcat_lst_lst_upd_tag_tag_fld_value: ALLCAT_LST_LST_UPD_TAG_TAG_FLD_VALUE,
} = ELEMENT_IDS;

const UpdateTagsContent = (props: UpdateTagContentProps): JSX.Element => {
  const {
    selectedTags,
    onExecuteRequestMassUpdate,
    isRemove,
    tagsField,
  } = props;

  // Modal Props
  const { modal = {} } = useGetAppState();
  const { modalProps = {} } = modal;
  const {
    nodeType,
    isSelectAll,
    selectedNodesIds,
    filterModel,
    countFilter,
  } = modalProps as UpdateTagsModalProps;

  // Custom Hooks
  const onCancel = useCancelModal();

  // Form
  const {
    control,
    setValue,
    setError,
    handleSubmit,
    formState: { isValid },
  } = useForm<UpdateTagsFormType>({
    defaultValues: {
      tags: selectedTags,
      overwrite: false,
    },
    resolver: yupResolver(updateTagsSchema),
    mode: "onChange",
  });

  // Callbacks
  const onSubmit = useCallback(
    (values: UpdateTagsFormType) => {
      onExecuteRequestMassUpdate([
        {
          tags: [...(values?.tags || [])],
          tags_action: isRemove ? "DEL" : values?.overwrite ? "OVW" : "ADD",
          isSelectAll: isSelectAll || false,
          [nodeType === "TRM"
            ? "trm_ids"
            : nodeType === "TBL"
            ? "tbl_ids"
            : nodeType === "COL"
            ? "col_ids"
            : nodeType === "RLS"
            ? "rule_ids"
            : "bo_ids"]: [...(selectedNodesIds || [])],
          filterModel: filterModel || {},
          countFilter: countFilter || "all",
        },
      ]);
    },
    [
      isSelectAll,
      isRemove,
      selectedNodesIds,
      filterModel,
      countFilter,
      nodeType,
    ]
  );

  return (
    <UpdateTagsStyled isRemovedView={isRemove}>
      <FormStyled
        isItemColumnLayout
        paddingLeft="100px"
        onFinish={handleSubmit(onSubmit) as HandleSubmitType}
      >
        <div className="content-sec">
          <FormItemStyled label={isRemove ? "Tags to be removed" : "Tags"}>
            <div id={ALLCAT_LST_LST_UPD_TAG_TAG_FLD}>
              <SelectField
                listHeight={120}
                allowClear
                maxHeight="150px"
                width="348px"
                isAllowClear={false}
                showSearch
                control={control}
                name="tags"
                options={tagsField?.field_data?.map((item, index) => {
                  const splittedVal = item?.value_name?.split(".") || [];
                  const tagsetName = splittedVal?.[0] || "";
                  // const tagName = splittedVal?.slice(-1)?.[0] || "";
                  const tagName =
                    splittedVal
                      ?.slice(1, splittedVal?.length)
                      ?.toString()
                      ?.replace(",", ".") || "";
                  const finalVal = `${tagsetName} > ${tagName}`;

                  return {
                    key: item?.id,
                    value: item?.id,
                    label: <div title={finalVal}>{finalVal}</div>,
                    labelText: item?.value_name,
                    id: `${ALLCAT_LST_LST_UPD_TAG_TAG_FLD_VALUE}-${index + 1}`,
                  };
                })}
                setError={setError}
                setValue={setValue}
                mode="multiple"
                virtual
                filterOption={(input: string, option: any): boolean =>
                  option?.labelText
                    ?.toString()
                    .toLowerCase()
                    .includes(input?.toString().toLowerCase())
                }
                placeholder="Select"
              />
            </div>
          </FormItemStyled>

          {!isRemove && (
            <FormItemStyled className="overwrite-form-item">
              <CheckboxField control={control} name="overwrite">
                Overwrite Tags
              </CheckboxField>
            </FormItemStyled>
          )}
          <div className="note">
            <IconWithText
              icon={infoIcon}
              text="Any changes to Tags will also be updated on the data
                   assets linked to the Glossary Terms"
            />
          </div>
        </div>

        <FormItemStyled
          label=""
          className="form-actions-sec"
          marginBottom="0px"
        >
          <Button id="cancel" width="74px" height="39px" onClick={onCancel}>
            Cancel
          </Button>
          <Button
            id="primary"
            width="74px"
            height="39px"
            marginLeft="8px"
            htmlType="submit"
            disabled={!isValid}
            elementId={ALLCAT_LST_LST_UPD_TAG_APPLY_BTN}
          >
            {isRemove ? "Remove" : "Add"}
          </Button>
        </FormItemStyled>
      </FormStyled>
    </UpdateTagsStyled>
  );
};

const UpdateTags = (props: UpdateTagsProps): JSX.Element => {
  const { modal = {} } = useGetAppState();
  const { modalProps = {} } = modal;
  const {
    nodeType,
    tags: selectedTagsIds = [],
    searchId,
    selectedRowsCount,
    populateData,
  } = modalProps as UpdateTagsModalProps;

  const { isRemove } = props;

  const onCancel = useCancelModal();

  const { parsedData, isLoading, error } = useGetMassEditableFields(
    nodeType,
    true
  );

  const tagsField = useMemo(() => {
    return (
      parsedData?.fields?.filter(
        (field) => field?.field_name === "tags"
      )?.[0] || []
    );
  }, [parsedData]);

  const alreadySelectedTags = useMemo(() => {
    return (
      tagsField?.field_data?.filter((tag) =>
        selectedTagsIds?.includes(tag?.id)
      ) || []
    );
  }, [selectedTagsIds, tagsField]);

  const alreadySelectedTagsIds = useMemo(
    () => alreadySelectedTags?.map((tag) => tag?.id) || [],
    [alreadySelectedTags]
  );

  const onSuccessMassUpdate = useCallback(() => {
    populateData({});

    onCancel();

    openNotification(
      <SuccessNotificationMessage
        message={`${selectedRowsCount} ${
          nodeType === "TRM"
            ? "term"
            : nodeType === "TBL"
            ? "table"
            : nodeType === "COL"
            ? "column"
            : nodeType === "RLS"
            ? "rule"
            : "dataset"
        }${selectedRowsCount > 1 ? "s have" : " has"} been updated.`}
      />
    );
  }, [selectedRowsCount]);

  const {
    onExecuteRequest: onExecuteRequestMassUpdate,
    isLoading: isLoadingMassUpdate,
    error: errorMassUpdate,
  } = useRequestWithMethod(
    "update_field_data",
    [nodeType, searchId],
    false,
    onSuccessMassUpdate
  );

  return (
    <StateHandler
      isFetching={isLoading || isLoadingMassUpdate}
      error={error || errorMassUpdate}
      isModal
    >
      <UpdateTagsContent
        key={isLoading ? "" : "add-tags"}
        tagsField={tagsField}
        onExecuteRequestMassUpdate={onExecuteRequestMassUpdate}
        isRemove={isRemove}
        selectedTags={alreadySelectedTagsIds}
      />
    </StateHandler>
  );
};

export default UpdateTags;
