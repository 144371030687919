import styled from "styled-components";

export const CloneRuleModalStyled = styled.div`
  padding-top: 30px;

  .label {
    font-family: OpenSansSemiBold;
    font-size: 14px;
    padding-left: 80px;
  }
`;
export const LabelAndCheckWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: start;
  row-gap: 5px;
  margin-right: 180px;

  /* .checkbox-field-container {
    width: 500px;
  } */
`;
export const LabelAndInputWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  row-gap: 5px;

  .select-field-container {
    width: 500px;

    .ant-select {
      width: 100%;

      .ant-select-selector {
        height: 40px;

        .ant-select-selection-item {
          .icon {
            display: none;
          }
        }
      }
    }
  }
  .input-field-container {
    width: 500px;
    .ant-input {
      width: 100%;
      height: 40px;
    }
  }

  .metric-time-wrapper {
    display: flex;
    align-items: center;

    .icon {
      display: flex;
      align-items: center;
    }

    svg {
      height: 25px;
      path {
        fill: ${({ theme }): string => theme.textColors.GREY_18};
      }
    }
  }
`;

export const LabelStyled = styled.div`
  font-family: OpenSansSemiBold;
  font-size: 14px;

  .required {
    color: red;
    margin-left: 2px;
  }
`;

export const DescriptionStyled = styled.div`
  color: ${({ theme }): string => theme.textColors.GREY_26};
  font-size: 12px;
`;

export const CloneRuleInputsWrapperStyled = styled.div`
  margin: 0 auto;
  padding: 20px 0 60px;
  display: flex;
  align-items: center;
  flex-direction: column;
  row-gap: 20px;
  justify-content: center;
  width: 100%;
`;

export const MultipleSourceWrapperStyled = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;

  .header {
    border: 1px solid ${({ theme }): string => theme.bgColors.LIGHT_GREY_51};
    background: ${({ theme }): string => theme.bgColors.LIGHT_GREY_22};
    padding: 8px 15px;
    font-family: OpenSansSemiBold;
    min-width: 250px;
  }

  .source-list {
    .select-field-container {
      width: 250px;
      height: 100%;

      .ant-select-selector {
        border-bottom: 0;
        border-left: 0;
        cursor: pointer;
      }

      .ant-select-arrow {
        display: none;
      }

      .ant-select:not(.ant-select-customize-input) .ant-select-selector {
        border: none;
      }

      :first-child {
        .ant-select-selector {
          border-top: 0;
        }
      }
    }
  }

  .source-list-item {
    padding: 10px 15px;
    border-bottom: 1px solid
      ${({ theme }): string => theme.bgColors.LIGHT_GREY_51};
  }

  :last-child {
    border-bottom: none;
  }

  .source-item {
    border-right: 1px solid
      ${({ theme }): string => theme.bgColors.LIGHT_GREY_51};
    border-left: 1px solid
      ${({ theme }): string => theme.bgColors.LIGHT_GREY_51};
  }

  .target-item {
    height: 43px;
    border-bottom: 1px solid
      ${({ theme }): string => theme.bgColors.LIGHT_GREY_51};
    border-right: 1px solid
      ${({ theme }): string => theme.bgColors.LIGHT_GREY_51};
  }
`;
