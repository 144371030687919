import { useLocalStorage } from "usehooks-ts";
import { useCallback, useState, cloneElement } from "react";

import Button from "../button";
import DvSumPopover from "../dvsumpopover";

import {
  ConfirmationPopoverContentStyled,
  ConfirmationPopoverStyled,
} from "./confirmationpopover.styles";

import {
  ConfirmationPopoverContentProps,
  ConfirmationPopoverProps,
} from "./confirmationpopover.types";

import { ELEMENT_IDS, LOCAL_STORAGE_CONSTANTS } from "../../constants";
import { useGetAppState } from "../../customhooks";

import Checkbox from "../checkbox";
import ConditionalWrapper from "../conditionalwrapper";

import { jsonParse, jsonStringify } from "../../utils";

const ConfirmationPopoverContent = (
  props: ConfirmationPopoverContentProps
): JSX.Element => {
  const {
    onCancel,
    onOk,
    okText,
    cancelText,
    heading,
    desc,
    donotRemindMeCheck = false,
    storageKey = "",
    // already set width to 268px, just adding it here instead of SC
    width = "268px",
  } = props;

  const [checkboxValue, setCheckboxValue] = useState<boolean>(false);

  const [confirmPopover, setConfirmPopover] = useLocalStorage<string>(
    LOCAL_STORAGE_CONSTANTS.show_confirmation_popover,
    ""
  );

  const onCheckBoxChange = useCallback((event) => {
    event?.stopPropagation();
    const updatedValue = event?.target?.checked;
    setCheckboxValue(updatedValue);
  }, []);

  const onConfirm = useCallback(
    (event) => {
      const finalValue = checkboxValue;

      const currentStorageValue =
        confirmPopover && confirmPopover?.trim() !== ""
          ? jsonParse(confirmPopover)
          : {};

      const updatedStorageValue = {
        ...currentStorageValue,
        [storageKey]: !finalValue,
      };

      const localStorageValue = jsonStringify(updatedStorageValue);

      if (finalValue) setConfirmPopover(localStorageValue);
      onOk?.(event);
    },
    [onOk, checkboxValue, confirmPopover, storageKey]
  );

  return (
    <ConfirmationPopoverContentStyled width={width}>
      <div className="heading" title={heading}>
        {heading}
      </div>
      <div className="desc">{desc}</div>

      {donotRemindMeCheck && (
        <div onClick={(e): void => e?.stopPropagation()} role="button">
          <Checkbox
            name="show_confirmation_popover"
            value={checkboxValue}
            onChange={onCheckBoxChange}
          >
            <label className="label">Do not show this again</label>
          </Checkbox>
        </div>
      )}

      <div className="action-sec">
        <Button
          id="cancel"
          height="27.2px"
          onClick={onCancel}
          elementId={ELEMENT_IDS.chat_bot_refr_all_conf_popovr_cancel_btn}
          data-testid="confirmation-popover-cancel-btn"
        >
          {cancelText}
        </Button>
        <Button
          id="primary"
          height="27.2px"
          onClick={onConfirm}
          elementId={ELEMENT_IDS.chat_bot_refr_all_conf_popovr_ok_btn}
          data-testid="confirmation-popover-ok-btn"
        >
          {okText}
        </Button>
      </div>
    </ConfirmationPopoverContentStyled>
  );
};
const ConfirmationPopover = (props: ConfirmationPopoverProps): JSX.Element => {
  const {
    children,
    placement = "bottomRight",
    arrowPointAtCenter = false,
    onCancel,
    onOk,
    visible,
    zIndex,
    propOnVisibleChange,
    storageKey = "",
    ...resProps
  } = props;

  const [confirmPopover, _] = useLocalStorage<string>(
    LOCAL_STORAGE_CONSTANTS.show_confirmation_popover,
    ""
  );

  const currentStorageValue =
    confirmPopover && confirmPopover?.trim() !== ""
      ? jsonParse(confirmPopover)
      : {};

  const shouldTriggerPopover = currentStorageValue?.[storageKey] ?? true;

  const [isPopoverVisible, setIsPopoverVisible] = useState(false);

  const { isOnboardingMode } = useGetAppState();

  const onVisibleChange = useCallback(
    (visibility: boolean) => {
      setIsPopoverVisible(visibility);
      propOnVisibleChange?.(visibility);
    },
    [propOnVisibleChange]
  );

  const onCancelClick = useCallback(() => {
    onCancel?.();

    setIsPopoverVisible(false);
    propOnVisibleChange?.(false);
  }, [onCancel]);

  const onOkClick = useCallback(
    (event) => {
      onOk?.(event);
      setIsPopoverVisible(false);
      propOnVisibleChange?.(false);
    },
    [onOk]
  );

  return (
    <ConfirmationPopoverStyled>
      <ConditionalWrapper
        condition={shouldTriggerPopover}
        wrapper={(child): JSX.Element => (
          <DvSumPopover
            content={
              <ConfirmationPopoverContent
                {...resProps}
                onCancel={onCancelClick}
                onOk={onOkClick}
                storageKey={storageKey}
              />
            }
            placement={placement}
            trigger="click"
            {...(isOnboardingMode && !isPopoverVisible && { onVisibleChange })}
            {...(!isOnboardingMode ? { onVisibleChange } : {})}
            visible={visible || isPopoverVisible}
            arrowPointAtCenter={arrowPointAtCenter}
            zIndex={zIndex}
            onVisibleChange={onVisibleChange}
            showArrow
          >
            {child || <></>}
          </DvSumPopover>
        )}
      >
        {cloneElement(children, {
          onClick: (event: React.MouseEvent) => {
            event?.stopPropagation();
            if (!shouldTriggerPopover) {
              event?.stopPropagation();
              onOkClick?.(event);
            }
          },
        })}
      </ConditionalWrapper>
    </ConfirmationPopoverStyled>
  );
};

export default ConfirmationPopover;
