import { useCallback, useMemo, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";

import { equals } from "ramda";
import { useHistory } from "react-router";

import { Button, DateFormatInput } from "../../../../components";

import {
  ChatPreferencesViewStyled,
  SecDescStyled,
  SecTitleStyled,
} from "./chatpreferencesview.styles";

import {
  ChatPreferencesViewFormType,
  ChatPreferencesViewProps,
  EditModeFields,
} from "./chatpreferencesview.types";

import FormItemLabel from "../../../../components/form/formitemlabel";
import {
  RadioGroupField,
  SelectField,
} from "../../../../components/formfields";

import {
  checkIsChatPage,
  getChatPrefrencesData,
  getDvSumInformativeText,
  openNotification,
  sortObjectsArrayByKey,
  updateChatPrefrencesData,
} from "../../../../utils";

import { FormStyled } from "../../../../components/form";
import SwitchField from "../../../../components/formfields/switchfield";

import { MetricFormat } from "./chatpreferencesview.components";
import Flex from "../../../../components/flex";

import SuccessNotificationMessage from "../../../../components/successnotificationmessagerendrer";
import { useRequestWithMethod } from "../../../../api";

import StateHandler from "../../../../components/statehandler";
import { useCancelModal, useGetAppState } from "../../../../customhooks";

import {
  getUserQrysPrefrOfAnsOutput,
  updateUserQrysPrefrOfAnsOutput,
} from "../../../analysisdetailpage/analisisdetailpage.utils";

import { ChatPrefrencesType } from "../../../../parsers";
import RouterPrompt from "../../../../components/routerprompt";

import { ELEMENT_IDS } from "../../../../constants";
import { useGetRefByType } from "../../../../api/refservice";

const {
  chat_pref_def_ans_view: CHAT_PREF_DEF_ANS_VIEW,
  chat_pref_ans_outp: CHAT_PREF_ANS_OUTP,
  chat_pref_ans_outp_show_log: CHAT_PREF_ANS_OUTP_SHOW_LOG,
  chat_pref_save_btn: CHAT_PREF_SAVE_BTN,
  chat_pref_def_chart_sort: CHAT_PREF_DEF_CHART_SORT,
  chat_pref_chrt_sort_asc: CHAT_PREF_CHRT_SORT_ASC,
  chat_pref_chrt_sort_desc: CHAT_PREF_CHRT_SORT_DESC,
  chat_pref_clps_vw: CHAT_PREF_CLPS_VW,
  chat_pref_lng: CHAT_PREF_LNG,
  chat_pref_clps_vw_qst_onl: CHAT_PREF_CLPS_VW_QST_ONL,
  chat_pref_clps_vw_def_vw: CHAT_PREF_CLPS_VW_DEF_VW,
} = ELEMENT_IDS;

const ChatPreferencesView = (props: ChatPreferencesViewProps): JSX.Element => {
  const { lastPage, isCallingInModal } = props;

  const history = useHistory();
  const onCancelModal = useCancelModal();

  const { isOnboardingMode } = useGetAppState();

  const isChatPage = checkIsChatPage();

  const {
    ssa_enbl_grd_vw: ssaEnblGrdVw,
    ssa_ans_op: ssaAnsOp,
    ssa_data_frmt: ssaDataFrmt,
    ssa_mtrc_frmt: ssaMtrcFrmt,
    cht_pref_dflt_srt: chtPrefDfltSrt,
    ssa_clps_vw: ssaClpsVw,
    ssa_dt_frmt: ssaDtFrmt,
    ssa_dft_res_type: ssaDftResType,
  } = getDvSumInformativeText();

  const chatPreferences = getChatPrefrencesData();

  const {
    default_answer_view: defaultAnswerView,
    show_log: showLog = false,
    show_code: showCode = false,
    show_explanation: showExplanation = false,
    collapse_view: collapseView = "default_view",
    date_format: dateFormat,
    metric_format: metricFormat,
    default_chart_sorting: defaultChartSorting,
    default_response_type: defaultResponseType,
  } = chatPreferences;

  const chatPreferencesViewForm = useForm<ChatPreferencesViewFormType>({
    defaultValues: {
      default_answer_view: defaultAnswerView || "chart",
      show_log: showLog,
      show_code: showCode,
      show_explanation: showExplanation,
      collapse_view: collapseView,
      date_format: dateFormat,
      default_chart_sorting: defaultChartSorting,
      default_response_type: defaultResponseType,
    },
    mode: "onChange",
  });

  const {
    formState: { isDirty },
    handleSubmit,
    watch,
    reset,
    control,
    setValue,
  } = chatPreferencesViewForm;

  const {
    show_log: watchedShowLog,
    show_code: watchedShowCode,
    show_explanation: watchedShowExplanation,
  } = watch();

  const [percentFormatMetrics, setPercentFormatMetrics] = useState<string[]>(
    metricFormat?.percentage_cols_list || []
  );
  const [currencyFormatMetrics, setCurrencyFormatMetrics] = useState<string[]>(
    metricFormat?.currency_cols_list || []
  );

  const [fieldsInEditMode, setFieldsInEditMode] = useState<EditModeFields>({});

  const isMetricFormatListDirty = useMemo(
    () =>
      !equals(percentFormatMetrics, metricFormat?.percentage_cols_list) ||
      !equals(currencyFormatMetrics, metricFormat?.currency_cols_list),
    [currencyFormatMetrics, percentFormatMetrics, metricFormat]
  );

  const onFieldEditButtonClick = useCallback(
    (fieldname: string, fieldId: string): void => {
      const fieldKey = `${fieldname}${fieldId}`;

      const fieldAlreadyExists = fieldsInEditMode?.[fieldKey];

      setFieldsInEditMode({
        ...fieldsInEditMode,
        [fieldKey]: !fieldAlreadyExists,
      });
    },
    [fieldsInEditMode]
  );

  const onCancel = useCallback(() => {
    if (isMetricFormatListDirty) {
      setPercentFormatMetrics(metricFormat?.percentage_cols_list || []);
      setCurrencyFormatMetrics(metricFormat?.currency_cols_list || []);
    }

    setFieldsInEditMode({});
    reset();
  }, [isMetricFormatListDirty, metricFormat]);

  const onPreferencesUpdateSuccess = useCallback(
    (res): void => {
      reset({ ...res?.data });
      setFieldsInEditMode({});

      updateChatPrefrencesData({
        ...res?.data,
      });

      const userQrysPrefrOfAnsOutput = getUserQrysPrefrOfAnsOutput();

      updateUserQrysPrefrOfAnsOutput({
        qrys_pref_show_code:
          chatPreferences?.show_code !== res?.data?.show_code
            ? {}
            : userQrysPrefrOfAnsOutput?.qrys_pref_show_code,
        qrys_pref_show_expl:
          chatPreferences?.show_explanation !== res?.data?.show_explanation
            ? {}
            : userQrysPrefrOfAnsOutput?.qrys_pref_show_expl,
        qrys_pref_show_logs:
          chatPreferences?.show_log !== res?.data?.show_log
            ? {}
            : userQrysPrefrOfAnsOutput?.qrys_pref_show_logs,
      });

      openNotification(
        <SuccessNotificationMessage
          message="Chat preferences updated successfully"
          showSuccess
        />
      );

      if (lastPage) {
        history.go(lastPage);
      }

      if (isCallingInModal) {
        onCancelModal();
      }
    },
    [lastPage, chatPreferences]
  );

  const { onExecuteRequest, isLoading, error } = useRequestWithMethod(
    "update_user_preferences",
    [],
    false,
    onPreferencesUpdateSuccess
  );

  const onSubmit = useCallback(
    (formValues: ChatPreferencesViewFormType) => {
      const updatedMetricFormat = {
        ...metricFormat,
        percentage_cols_list: percentFormatMetrics,
        currency_cols_list: currencyFormatMetrics,
      };

      const userPrefrencesPayload: ChatPrefrencesType = {
        ...chatPreferences,
        ...formValues,
        metric_format: updatedMetricFormat,
      };

      onExecuteRequest(userPrefrencesPayload);
    },
    [
      chatPreferences,
      percentFormatMetrics,
      currencyFormatMetrics,
      onExecuteRequest,
    ]
  );

  return (
    <StateHandler isFetching={isLoading} error={error} isModal>
      <ChatPreferencesViewStyled isChatPage={isChatPage}>
        <RouterPrompt
          when={isDirty || isMetricFormatListDirty}
          title="Discard Changes"
          desc="You have some unsaved changes. These changes will be lost if you leave this page."
        />

        <div className="form-wrapper">
          <FormProvider {...chatPreferencesViewForm}>
            <FormStyled isItemColumnLayout>
              <div className="form-section">
                <SecTitleStyled>View Setting</SecTitleStyled>
                <FormItemLabel
                  label={ssaEnblGrdVw?.field_name || ""}
                  description={ssaEnblGrdVw?.description || ""}
                  paddingLeft="0"
                  width="608px"
                  id={CHAT_PREF_DEF_ANS_VIEW}
                >
                  <RadioGroupField
                    name="default_answer_view"
                    control={control}
                    options={[
                      {
                        label: "Chart",
                        value: "chart",
                      },
                      {
                        label: "Table",
                        value: "grid",
                      },
                    ]}
                  />
                </FormItemLabel>

                <FormItemLabel
                  label={chtPrefDfltSrt?.field_name || ""}
                  description={chtPrefDfltSrt?.description || ""}
                  paddingLeft="0"
                  width="629px"
                  id={CHAT_PREF_DEF_CHART_SORT}
                >
                  <RadioGroupField
                    name="default_chart_sorting"
                    control={control}
                    options={[
                      {
                        label: "Ascending",
                        value: "asc",
                        id: CHAT_PREF_CHRT_SORT_ASC,
                      },
                      {
                        label: "Descending",
                        value: "desc",
                        id: CHAT_PREF_CHRT_SORT_DESC,
                      },
                    ]}
                  />
                </FormItemLabel>

                <FormItemLabel
                  label={ssaDftResType?.field_name}
                  description={ssaDftResType?.description}
                  paddingLeft="0"
                  width="629px"
                >
                  <RadioGroupField
                    name="default_response_type"
                    className="default-response-type-radio-field"
                    control={control}
                    options={[
                      {
                        label: "Default",
                        value: "DEFAULT",
                      },
                      {
                        label: "Simplified",
                        value: "SIMPLIFIED",
                      },
                      {
                        label: "Customer Facing",
                        value: "CUSTOMER_FACING",
                      },
                    ]}
                  />
                </FormItemLabel>

                <FormItemLabel
                  label={ssaAnsOp?.field_name || ""}
                  description={ssaAnsOp?.description || ""}
                  paddingLeft="0"
                  width="608px"
                  id={CHAT_PREF_ANS_OUTP}
                >
                  <Flex
                    direction="column"
                    rowGap={16}
                    alignItems="flex-start"
                    customClass="default-answer-output-field"
                  >
                    <SwitchField
                      checked={watchedShowLog}
                      control={control}
                      name="show_log"
                      label="Show Log"
                      checkedChildren=""
                      unCheckedChildren=""
                      isLabelBold={false}
                      width="28.8px"
                      id={CHAT_PREF_ANS_OUTP_SHOW_LOG}
                    />
                    <SwitchField
                      checked={watchedShowCode}
                      control={control}
                      name="show_code"
                      label="Show Code"
                      checkedChildren=""
                      unCheckedChildren=""
                      isLabelBold={false}
                      width="28.8px"
                    />
                    <SwitchField
                      checked={watchedShowExplanation}
                      control={control}
                      name="show_explanation"
                      label="Show Explanation"
                      checkedChildren=""
                      unCheckedChildren=""
                      isLabelBold={false}
                      width="28.8px"
                    />
                  </Flex>
                </FormItemLabel>

                <FormItemLabel
                  label={ssaClpsVw?.field_name || ""}
                  description={ssaClpsVw?.description || ""}
                  paddingLeft="0"
                  width="608px"
                  id={CHAT_PREF_CLPS_VW}
                >
                  <RadioGroupField
                    name="collapse_view"
                    control={control}
                    options={[
                      {
                        label: "Questions and Answers",
                        value: "default_view",
                        id: CHAT_PREF_CLPS_VW_DEF_VW,
                      },
                      {
                        label: "Questions Only",
                        value: "questions_only",
                        id: CHAT_PREF_CLPS_VW_QST_ONL,
                      },
                    ]}
                  />
                </FormItemLabel>
              </div>

              <div className="form-section">
                <SecTitleStyled marginBottom="0px">
                  {ssaDataFrmt?.field_name || ""}
                </SecTitleStyled>

                <SecDescStyled>{ssaDataFrmt?.description || ""}</SecDescStyled>

                <FormItemLabel
                  label={ssaDtFrmt?.field_name}
                  description={ssaDtFrmt?.description}
                  paddingLeft="0"
                  width="369.6px"
                  className="date-format-wrapper"
                >
                  <DateFormatInput />
                </FormItemLabel>
              </div>

              <div className="form-section">
                <MetricFormat
                  label={ssaMtrcFrmt?.field_name || ""}
                  description={ssaMtrcFrmt?.description || ""}
                  currencyFormatMetrics={currencyFormatMetrics}
                  percentFormatMetrics={percentFormatMetrics}
                  fieldsInEditMode={fieldsInEditMode}
                  setCurrencyFormatMetrics={setCurrencyFormatMetrics}
                  setPercentFormatMetrics={setPercentFormatMetrics}
                  onFieldEditButtonClick={onFieldEditButtonClick}
                />
              </div>
            </FormStyled>
          </FormProvider>
        </div>

        <div className="action-sec">
          <Button
            id="cancel"
            {...(isCallingInModal
              ? { onClick: onCancelModal }
              : {
                  onClick: onCancel,
                  disabled: !isDirty && !isMetricFormatListDirty,
                })}
          >
            Cancel
          </Button>
          <Button
            disabled={!isDirty && !isMetricFormatListDirty && !isOnboardingMode}
            onClick={handleSubmit(onSubmit)}
            tooltipProps={{
              title:
                !isDirty && !isMetricFormatListDirty
                  ? "You haven't changed anything"
                  : "",
              placement: "topRight",
            }}
            elementId={CHAT_PREF_SAVE_BTN}
          >
            Save
          </Button>
        </div>
      </ChatPreferencesViewStyled>
    </StateHandler>
  );
};

export default ChatPreferencesView;
