import { useHistory } from "react-router";
import { useCallback } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";

import {
  AddDashboardGovernanceViewFormStyled,
  DashboardGovViewFormStyled,
} from "./dashboardgovviewform.styles";

import {
  AddDashboardGovernanceViewFormType,
  EditDashboardGovViewFormPropsType,
} from "./dashboardgovviewform.types";

import { addGovernanceViewSchema } from "../../../../utils/schemas/governanceviewschemas";
import StateHandler from "../../../statehandler/statehandler";

import { useRequestWithMethod } from "../../../../api";
import {
  useCancelModal,
  useGetAppState,
  useSetData,
} from "../../../../customhooks";

import { FormItemStyled, FormStyled } from "../../../form";
import { InputField, TextAreaField } from "../../../formfields";

import Button from "../../../button";
import { DASHBORD_QUERY_PARAMS } from "../../../../pages/dashboard/dashboard.types";
import { API_CONFIG } from "../../../../constants/apiconfig";
import { ELEMENT_IDS } from "../../../../constants";

const {
  add_dash_name_field: ADD_DASH_NAME_FIELD,
  add_dash_desc_field: ADD_DASH_DESC_FIELD,
  add_dash_save_btn: ADD_DASH_SAVE_BTN,
} = ELEMENT_IDS;

const DashboardGovViewForm = (): JSX.Element => {
  const onCancel = useCancelModal();
  const history = useHistory();
  const onSetData = useSetData();

  const {
    modal: { modalProps = {} },
  } = useGetAppState();

  const {
    get_dashboard_current_node: getDashboardCurrentNode,
    get_dashboard_governance_views: getDashboardGovernanceViews,
  } = API_CONFIG;

  const {
    id = "0",
    view_name = "",
    view_desc = "",
    isEditMode,
    isCloneMode = false,
  } = modalProps as EditDashboardGovViewFormPropsType;

  const addDashboardGovViewForm = useForm<AddDashboardGovernanceViewFormType>({
    defaultValues: {
      view_name,
      view_desc,
    },
    resolver: yupResolver(addGovernanceViewSchema),
    mode: "onChange",
  });

  const {
    control,
    handleSubmit,
    trigger,
    watch,
    formState: { isValid },
  } = addDashboardGovViewForm;

  const onSuccess = useCallback((response) => {
    const data = response?.data;

    onSetData(getDashboardCurrentNode, data?.current);
    onSetData(getDashboardGovernanceViews, data?.listing);

    const { NODE_ID } = DASHBORD_QUERY_PARAMS;
    const currentPath = history?.location;

    const nodeId = data?.current[0]?.DSH_ID;

    history?.push(`/dashboard/${String(nodeId)}`);
    onCancel();
  }, []);

  const { isLoading, error, onExecuteRequest } = useRequestWithMethod(
    isEditMode
      ? "update_dashboard_governance_view"
      : "create_dashboard_governance_view",
    isEditMode ? [id] : undefined,
    true,
    onSuccess
  );

  const onSubmit = useCallback(
    (values) => {
      const payload = {
        dsh_name: values?.view_name,
        dsh_desc: values?.view_desc,
        ...(isCloneMode && { action: "CLONE", dsh_id: id }),
      };

      onExecuteRequest(
        payload,
        isEditMode ? [id] : undefined,
        undefined,
        isEditMode
          ? "update_dashboard_governance_view"
          : "create_dashboard_governance_view"
      );

      // onSuccess({ data: {} });
    },
    [id, isEditMode]
  );

  return (
    <DashboardGovViewFormStyled>
      <StateHandler error={error} isFetching={isLoading} isModal>
        <AddDashboardGovernanceViewFormStyled>
          <FormStyled
            paddingLeft="123px"
            paddingRight="123px"
            onFinish={handleSubmit(onSubmit) as any}
            isItemColumnLayout
          >
            <FormProvider {...addDashboardGovViewForm}>
              <FormItemStyled label="Name" required>
                <InputField
                  control={control}
                  name="view_name"
                  placeholder="Enter Dashboard Name"
                  className="view-name"
                  width="400px"
                  id={ADD_DASH_NAME_FIELD}
                />
              </FormItemStyled>

              <FormItemStyled label="Description">
                <TextAreaField
                  control={control}
                  name="view_desc"
                  width="400px"
                  placeholder="Enter Description"
                  id={ADD_DASH_DESC_FIELD}
                />
              </FormItemStyled>
            </FormProvider>

            <FormItemStyled
              label=""
              className="form-actions-sec"
              marginBottom="0px"
            >
              <Button id="cancel" width="74px" height="39px" onClick={onCancel}>
                Cancel
              </Button>
              <Button
                id="primary"
                width="74px"
                height="39px"
                marginLeft="8px"
                htmlType="submit"
                disabled={!isValid}
                elementId={ADD_DASH_SAVE_BTN}
              >
                {isEditMode ? "Save" : isCloneMode ? "Clone" : "Create"}
              </Button>
            </FormItemStyled>
          </FormStyled>
        </AddDashboardGovernanceViewFormStyled>
      </StateHandler>
    </DashboardGovViewFormStyled>
  );
};

export default DashboardGovViewForm;
