import { ChangeEvent, useCallback, useMemo, useState } from "react";
import {
  AgentConfigSecStyled,
  SectionLabelwithDescStyled,
} from "./agentconfigsec.styles";

import { AgentConfigSecProps } from "./agentconfigsec.types";
import { getDvSumInformativeText } from "../../../../../../../../utils";

import { AnalysisConfigurationType } from "../../../../../../../../parsers";
import { AnalaysisPageState } from "../../../../../..";

import FormItemLabel from "../../../../../../../../components/form/formitemlabel";
import {
  InputFieldContainerStyled,
  TextAreaFieldStyled,
} from "../../../../../../../../components/formfields/textareafield/textareafield.styles";

import ShowMoreOrLessText from "../../../../../../../../components/showmoreorlesstext";
import { REQUIRED_MESSAGE } from "../../../../../../../../constants/formconstants";

import { FormStyled } from "../../../../../../../../components/form";
import { ChatAgentField } from "../../../../definitiontab.components";
import { HorizontalDividerStyled } from "../../../../../../../../components/dividers/dividers.styles";

//TODO: Will move this into reusable component
const LabelwithDesc = ({
  label = "",
  desc = "",
}: {
  label: string;
  desc: string;
}): JSX.Element => {
  return (
    <SectionLabelwithDescStyled>
      <div className="sec-label">{label}</div>
      <div className="sec-desc">{desc}</div>
    </SectionLabelwithDescStyled>
  );
};

const AgentConfigSec = (props: AgentConfigSecProps): JSX.Element => {
  const {
    ssa_prmpt_tmp_tool_slc_lgc: ssaPrmptTmpBotSlcLgc,
    ssa_prmpt_tmp_int_lgc: ssaPrmptTmpIntLgc,
    ssa_prmpt_tmp_aud_tlr: ssaPrmptTmpAudTlr,
    ssa_prmpt_tmp_api_res_frmt: ssaPrmptTmpApiResFrmt,
    ssa_prmpt_tmp_aud_tlr_cf: ssaPrmptTmpAudTlrCf,
    ssa_prmpt_tmp_aud_tlr_simp: ssaPrmptTmpAudTlrSimp,
  } = getDvSumInformativeText();

  const {
    isEditMode,
    configuration,
    updateConfiguration,
    aiChatModel,
    aiChatModelName,
    onChangeAIChatModel,
    toolsList = [],
    isScopeEnabled,
    setIsFormValid,
  } = props;

  const { prompt_inputs: promptInputs } = configuration || {};

  const [dirtyFields, setDirtyFields] = useState<Record<string, boolean>>({});

  const promptInputsConfig: Array<{
    label: string;
    key: keyof AnalysisConfigurationType["prompt_inputs"];
    height: string;
    description: string;
    required?: boolean;
    placeholder?: string;
    isLabel?: boolean;
    childKey?: keyof AnalysisConfigurationType["prompt_inputs"]["audience_tailoring"];
    value: string;
  }> = useMemo(
    () => [
      {
        label: ssaPrmptTmpIntLgc?.field_name,
        required: true,
        key: "intent_logic",
        height: "250px",
        description: ssaPrmptTmpIntLgc?.description,
        placeholder: ssaPrmptTmpIntLgc?.placeholder,
        value: promptInputs?.intent_logic,
      },
      {
        label: ssaPrmptTmpBotSlcLgc?.field_name,
        required: isScopeEnabled ? toolsList?.length > 1 : true,
        key: "tool_selection_logic",
        height: "250px",
        description: ssaPrmptTmpBotSlcLgc?.description,
        placeholder: ssaPrmptTmpBotSlcLgc?.placeholder,
        value: promptInputs?.tool_selection_logic,
      },
      {
        label: ssaPrmptTmpApiResFrmt?.field_name,
        key: "api_response_format",
        height: "250px",
        description: ssaPrmptTmpApiResFrmt?.description,
        placeholder: ssaPrmptTmpApiResFrmt?.placeholder,
        value: promptInputs?.api_response_format,
      },
      {
        label: ssaPrmptTmpAudTlr?.field_name,
        key: "audience_tailoring",
        height: "250px",
        description: ssaPrmptTmpAudTlr?.description,
        placeholder: ssaPrmptTmpAudTlr?.placeholder,
        isLabel: true,
        value: "",
      },

      {
        label: ssaPrmptTmpAudTlrSimp?.field_name,
        key: "audience_tailoring",
        childKey: "simplified",
        height: "250px",
        description: ssaPrmptTmpAudTlrSimp?.description,
        placeholder: ssaPrmptTmpAudTlrSimp?.placeholder,
        value: promptInputs?.audience_tailoring?.simplified,
      },
      {
        label: ssaPrmptTmpAudTlrCf?.field_name,
        key: "audience_tailoring",
        childKey: "customer_facing",
        height: "250px",
        description: ssaPrmptTmpAudTlrCf?.description,
        placeholder: ssaPrmptTmpAudTlrCf?.placeholder,
        value: promptInputs?.audience_tailoring?.customer_facing,
      },
    ],
    [
      ssaPrmptTmpBotSlcLgc,
      ssaPrmptTmpIntLgc,
      promptInputs,
      toolsList?.length,
      isScopeEnabled,
    ]
  );

  const onFieldChange = useCallback(
    (
      key: keyof AnalaysisPageState["configuration"]["prompt_inputs"],
      childKey?: keyof AnalysisConfigurationType["prompt_inputs"]["audience_tailoring"]
    ) => (e: ChangeEvent<HTMLTextAreaElement>): void => {
      setDirtyFields({ ...dirtyFields, [key]: true });
      updateConfiguration(e?.target?.value, key, childKey);
    },
    [updateConfiguration]
  );

  return (
    <AgentConfigSecStyled isEditMode={isEditMode}>
      <FormStyled isItemColumnLayout className="configuration-form">
        <ChatAgentField
          isEditMode={isEditMode}
          aiChatModel={aiChatModel}
          aiChatModelName={aiChatModelName}
          onChangeAIChatModel={onChangeAIChatModel}
          setIsFormValid={setIsFormValid}
        />
        {isEditMode ? (
          promptInputsConfig.map((item) =>
            item?.isLabel ? (
              <>
                <HorizontalDividerStyled
                  marginTop="25px"
                  marginBottom="25px"
                  height="0.5px"
                />
                <LabelwithDesc label={item?.label} desc={item?.description} />
              </>
            ) : (
              <FormItemLabel
                label={item?.label}
                paddingLeft="0"
                required={item?.required}
                key={`prompt-form-field-${item?.key}-${item?.childKey || ""}`}
                description={item?.description}
              >
                <InputFieldContainerStyled>
                  <TextAreaFieldStyled
                    value={item?.value}
                    onChange={onFieldChange(item?.key, item?.childKey)}
                    name={item?.key}
                    width="100%"
                    height={item?.height}
                    placeholder={item?.placeholder}
                  />
                  {dirtyFields?.[item?.key] &&
                    !promptInputs?.[item?.key] &&
                    item?.required && (
                      <div className="error">{REQUIRED_MESSAGE}</div>
                    )}
                </InputFieldContainerStyled>
              </FormItemLabel>
            )
          )
        ) : (
          <div className="readonly-config readonly-config-margin-top">
            {promptInputsConfig.map((item) =>
              item?.isLabel ? (
                <>
                  <HorizontalDividerStyled height="0.5px" />
                  <LabelwithDesc label={item?.label} desc="" />
                </>
              ) : (
                <div
                  className="config-item-wrapper"
                  key={`prompt-readonly-field-${item?.key}-${
                    item?.childKey || ""
                  }`}
                >
                  <div className="config-label">{item?.label}:</div>
                  <div className="config-value formatted-val">
                    <ShowMoreOrLessText
                      text={item?.value || "--"}
                      textLimit={350}
                    />
                  </div>
                </div>
              )
            )}
          </div>
        )}
      </FormStyled>
    </AgentConfigSecStyled>
  );
};

export default AgentConfigSec;
