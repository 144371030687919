import styled from "styled-components";
import { ellipsis } from "../../styles/app.styles";
import { RepresentModeType } from "./represents.types";

export const RepresentsStyled = styled.div`
  .represents-values {
    display: flex;
    flex-wrap: wrap;
  }

  .empty-represents {
    font-weight: 600;
    width: 100%;
    text-align: center;
    padding-top: 20px;
  }

  .more-items-count {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: 8px;
    padding: 0 10px;
    min-width: 20px;
    height: 20px;
    font-size: 10px;
    font-family: OpenSansBold;
    background: ${({ theme }): string => theme.bgColors.LIGHT_GREY_27};
    color: ${({ theme }): string => theme.textColors.BLACK_COLOR};
    border: 1px solid ${({ theme }): string => theme.borderColors.LIGHT_GREY_40};
  }
`;

const getMaxWidth = (hasTitle: boolean, isAllValuesExists: boolean): string => {
  if (hasTitle) return isAllValuesExists ? "15%" : "20%";
  return isAllValuesExists ? "20%" : "25%";
};

export const RepresentItemStyled = styled.div<{
  isRefView?: boolean;
  mode?: RepresentModeType;
  hasTitle?: boolean;
  isAllValuesExists?: boolean;
}>`
  position: relative;
  display: flex;
  align-items: center;
  width: 100%;
  /* flex-wrap: wrap; */

  .src-icon {
    svg {
      vertical-align: text-bottom;
    }
  }

  .schema-icon {
    svg {
      vertical-align: middle;

      path {
        fill: ${({ theme }): string => theme.textColors.BLUE_116};
      }
    }
  }

  .db-icon {
    svg {
      vertical-align: baseline;

      path {
        fill: ${({ theme }): string => theme.textColors.BLUE_116};
      }
    }
  }

  .represent-item-value {
    cursor: ${({ isRefView }): string => (isRefView ? "default" : "")};
    margin-left: 3px;
    color: ${({ theme, mode }): string =>
      mode === "primary"
        ? theme.textColors.DARK_BLUE
        : theme?.textColors?.grey_40};
    /* min-width: ${({ hasTitle }): string => (hasTitle ? "25px" : "15%")}; */
    /* max-width: ${({ hasTitle }): string => (hasTitle ? "20%" : "25%")}; */
    display: inline-block;
    ${ellipsis};

    &.src-connection-name,
    &.db-name,
    &.schema-name,
    &.tbl-name,
    &.col-name {
      max-width: ${({ hasTitle = false, isAllValuesExists = false }): string =>
        getMaxWidth(hasTitle, isAllValuesExists)};
    }

    &.db-name,
    &.schema-name {
      cursor: default;
      min-width: 6ch;
    }

    :hover {
      &.src-connection-name,
      &.tbl-name,
      &.col-name {
        text-decoration: ${({ isRefView }): string =>
          !isRefView ? "underline" : ""};
      }

      :hover {
        &.placeholder-tbl {
          text-decoration: none;
          cursor: default;
        }
      }
    }

    &.link-text {
      cursor: pointer;
    }

    div {
      ${ellipsis};
    }
  }
  .seperator-right-icon {
    margin: 0 5px;
  }

  .flex-1 {
    flex: 1;
    min-width: 10px;
  }

  svg {
    /* path {
          fill: ${({ theme, mode }): string =>
      mode === "primary" ? theme.bgColors.BLUE_116 : theme.bgColors.GREY_18};
        } */
  }

  .delete-icon {
    position: absolute;
    right: -15px;

    svg {
      vertical-align: middle;
      cursor: pointer;
      path {
        fill: ${({ theme }): string => theme.bgColors.RED_100};
      }
    }
  }
`;
