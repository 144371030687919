import { useParams, useHistory } from "react-router";
import { useCallback, useState } from "react";

import { DefinitionActionBarTypes } from "./definitionactionbar.types";

import { Button } from "../../../../../../components";

import { editIcon, lockIcon } from "../../../../../../svgs";
import { useOpenModal } from "../../../../../../customhooks";

import { DialogBoxProps } from "../../../../../../components/dialogbox/dialogbox.types";
import { DefinitionActionBarStyled } from "./definitionactionbar.styles";
import { ShareOwnerModalProps } from "../../../../../../forms/shareownerform/shareownerform.types";

const DefinitionActionBar = (props: DefinitionActionBarTypes): JSX.Element => {
  const { id: paramsAnalysisNodeId = "" } = useParams<{ id: string }>();
  const history = useHistory();

  const {
    isAuthor = false,
    isEditMode = true,
    handlers,
    analysisQueryBlocksInfo,
    isSaveButtonDisable = false,
    hasManageFieldsChanges = false,
    hasUserAccessToEdit,
    analysisSrcTypeId,
    hasConfgUrlExists,
    hasPromptExists,
    hasPinnedToolsExists,
    isScopeEnabled,
    isNotConnectedToAgent = false,
    isTool = false,
    isAgentPromptExists = false,
    isCloneMode = false,
    isFormValid = true,
  } = props;

  const { isAnyThingChanged = false } = analysisQueryBlocksInfo || {};

  const {
    onDiscardClick,
    onSaveDoneClick,
    onEditClick,
    onSaveAnalysisButtonClicked,
  } = handlers || {};

  const onOpenModal = useOpenModal();

  const [isDone, setIsDone] = useState(false);

  const handleDiscardClick = useCallback(() => {
    onOpenModal({
      modalId: "ssa_discard_analysis",
      visible: true,
      modalTitle: "Discard Changes",
      modalProps: {
        content:
          "Are you sure you want to discard these changes? The changes you made will not be reflected.",
        okText: "Discard",
        cancelOnSuccess: true,
        onSuccess: (): void => {
          setIsDone(false);
          onDiscardClick();
        },
      } as DialogBoxProps,
    });
  }, [onDiscardClick]);

  const ACCESS_OPTIONS = [
    {
      label: isTool ? "View Only" : "Chat Only",
      value: "viewer",
      key: "viewer",
    },
    { label: "Edit", value: "editor", key: "editor" },
  ];

  const onManageAccessClick = useCallback(() => {
    onOpenModal({
      modalId: "share_owner",
      visible: true,
      modalTitle: "Manage Access",
      modalProps: {
        nodeType: "ANL",
        accessOptionsInInput: ACCESS_OPTIONS,
        accessOptionsInListing: [
          ...ACCESS_OPTIONS,
          {
            label: "Remove Access",
            value: "remove-access",
            key: "remove-access",
          },
        ],
        nodeId: paramsAnalysisNodeId,
        successMessage: "Shared successfully",
      } as ShareOwnerModalProps,
    });
  }, [paramsAnalysisNodeId]);

  const handleCancelClick = useCallback(() => {
    setIsDone(false);
    if (!isAnyThingChanged && paramsAnalysisNodeId) {
      onDiscardClick();
    }
  }, [isAnyThingChanged, paramsAnalysisNodeId, onDiscardClick]);

  const onSaveSuccess = useCallback(() => {
    setIsDone(false);
    onSaveAnalysisButtonClicked();
    onSaveDoneClick?.();
  }, [onSaveDoneClick, onSaveAnalysisButtonClicked]);

  return (
    <DefinitionActionBarStyled>
      {!isEditMode && (isAuthor || hasUserAccessToEdit) && !isDone && (
        <Button
          className="manage-access-btn"
          icon={lockIcon()}
          onClick={onManageAccessClick}
          data-testid="analysis-detail-page-header-right-sec-action-btns-edit-btn"
        >
          Manage Access
        </Button>
      )}
      {!isEditMode && (isAuthor || hasUserAccessToEdit) && (
        <Button
          className="edit-btn"
          icon={editIcon}
          onClick={onEditClick}
          data-testid="analysis-detail-page-header-right-sec-action-btns-edit-btn"
        >
          Edit
        </Button>
      )}

      {isEditMode && !isDone && (
        <Button
          onClick={(): void => setIsDone(true)}
          data-testid="analysis-detail-page-header-right-sec-action-btns-done-btn"
        >
          Done
        </Button>
      )}

      {isEditMode && isDone && (
        <div className="save-btns-wrapper">
          <Button
            onClick={handleDiscardClick}
            disabled={
              isCloneMode
                ? false // Always enabled in clone mode
                : !paramsAnalysisNodeId ||
                  (!isAnyThingChanged && !hasManageFieldsChanges) // Edit mode condition
            }
            tooltipProps={{
              title: isCloneMode
                ? "" // No tooltip in clone mode
                : !paramsAnalysisNodeId
                ? "You cannot discard unsaved changes."
                : !isAnyThingChanged && !hasManageFieldsChanges
                ? "You haven't changed anything yet."
                : "",
              placement: "left",
            }}
            data-testid="analysis-detail-page-header-right-sec-action-btns-discard-btn"
          >
            Discard
          </Button>
          <Button
            marginLeft="3px"
            onClick={onSaveSuccess}
            disabled={
              isSaveButtonDisable || hasManageFieldsChanges || !isFormValid
            }
            tooltipProps={{
              title:
                analysisSrcTypeId === "RST"
                  ? !isTool && isScopeEnabled && !hasPinnedToolsExists
                    ? "You can't save the bot without providing the pinned tools information."
                    : !isTool && isAgentPromptExists
                    ? ""
                    : !hasPromptExists
                    ? "You can't save the bot without providing the prompt information."
                    : !hasConfgUrlExists
                    ? "You can't save the bot without providing the workflow configurations"
                    : ""
                  : isSaveButtonDisable
                  ? "If limit to specific tables is checked, you have to add at-least one table for Chat."
                  : hasManageFieldsChanges
                  ? "You have un-saved changes in manage fields section. Please save them first else they will be discarded."
                  : "",
              overlayClassName: "chat-bot-save-button",
              placement: "topLeft",
              overlayStyle: {
                maxWidth: 350,
              },
            }}
            data-testid="analysis-detail-page-header-right-sec-action-btns-save-btn"
          >
            Save
          </Button>
          <Button
            id="cancel"
            marginLeft="16px"
            onClick={handleCancelClick}
            data-testid="analysis-detail-page-header-right-sec-action-btns-cancel-btn"
          >
            Cancel
          </Button>
        </div>
      )}
    </DefinitionActionBarStyled>
  );
};

export default DefinitionActionBar;
