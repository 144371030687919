import { useCallback, useMemo } from "react";

//       <---BlankSlates--->
import { dataSourceFieldRefBlankSlate } from "../../blankslates";
//       <---BlankSlates--->

//       <---Components--->
import CoreNodesCircleIcon from "../../components/corenodetypescircleicon";
import LineageSectionTitleWithIcon from "../../components/lineagesectitlewithicon";

import NodeRefPageHOC from "../../components/noderefpagehoc";
import { NodeRefPageHOCEmbededProps } from "../../components/noderefpagehoc/noderefpagehoc.types";

import NodeStatusRendrer from "../../components/nodestatusrendrer";
import { Section } from "../../components/section";

import SectionHeader from "../../components/sectionheader";
import { SectionRowProps } from "../../components/sectionrow/sectionrow.types";

import StateHandler from "../../components/statehandler/statehandler";
import SuggestAnEditButton from "../../components/suggestaneditbutton";
//       <---Components--->

//       <---Custom Hokks--->
import { useGetAppState, useGetAppType } from "../../customhooks";
//       <---Custom Hokks--->

//       <---Renderers--->
import { dataSourceFieldRefViewRenderer } from "./datasourcefieldrefdrawer.rendrer";
//       <---Renderers--->

//       <---Services--->
import { useGetDataSourceFieldRefDoc } from "../../api/datasourcefieldservice/datasourcefieldservice";
//       <---Services--->

//       <---SVGS--->
import { chevronRight, databaseIcon } from "../../svgs";
//       <---SVGS--->

//       <---Styles--->
import { DataSourceFieldRefViewStyled } from "./datasourcefieldrefdrawer.styles";
//       <---Styles--->

//       <---Types--->
import { DataSourceFieldRefViewProps } from "./datasourcefieldrefdrawer.types";
//       <---Types--->

//       <---Utils--->
import { getSourceDetailPageUrl, getTableDetailPageUrl } from "../../utils";
import { StyledBreadcrum } from "../../components";

import { BreadcrumbItemType } from "../../components/styledbreadcrum";
//       <---Utils--->

const titleSecProps = {
  showBorderBottom: false,
  isShowIcon: false,
  paddingBottom: "0px",
};

function Content(
  props: DataSourceFieldRefViewProps & NodeRefPageHOCEmbededProps
): JSX.Element {
  const { isDrawer, id: propsId, onClickSuggest } = props;

  const { drawer = {} } = useGetAppState();
  const { isExtOrDesktop } = useGetAppType();

  const id = drawer?.drawerProps?.id || "";

  const { parsedData, isLoading, error } = useGetDataSourceFieldRefDoc(
    id,
    "DSF",
    "PUB"
  );

  const {
    is_editable: isEditable,
    lineage,
    node_desc: nodeDesc = "",
    node_id: nodeId = "",
    node_name: nodeName = "",
    node_title: nodeTitle = "",
    overview = [],
    publish_status: publishStatus = "",
    publish_status_id: publishStatusId,
    source_id: sourceId = "",
    source_name: sourceName = "",
    table_id: tableId = "",
    table_name: tableName = "",
    schema_name: schemaName = "",
    src_type_id: srcTypeId,
  } = parsedData || {};

  const getBreadcrumNodeLink = useCallback((id: string, isTable?: boolean) => {
    const url = isTable
      ? getTableDetailPageUrl(id)
      : getSourceDetailPageUrl(id);

    return url || "";
  }, []);

  const breadcrumItems: BreadcrumbItemType[] = useMemo(
    () => [
      {
        title: sourceName,
        srcTypeId,
        nodeId: sourceId,
        type: "src",
      },

      {
        title: schemaName,
        type: "schema",
      },

      {
        title: tableName,
        type: "dsr",
        nodeId: tableId,
      },
    ],
    [parsedData]
  );

  return (
    <StateHandler
      isFetching={isLoading}
      error={error}
      blankSlate={dataSourceFieldRefBlankSlate}
    >
      <DataSourceFieldRefViewStyled>
        <div className="datasource-field-ref-header">
          <StyledBreadcrum items={breadcrumItems} />

          <div className="header_row_2">
            <SectionHeader
              icon={
                <CoreNodesCircleIcon
                  nodeType="DSF"
                  marginRight="12px"
                  fontSize="12px"
                />
              }
              title={nodeName}
              subTitle=""
              rightIcon={
                <>
                  <NodeStatusRendrer
                    status={publishStatusId}
                    className="deprecate-icon"
                    tooltipTitle={publishStatus}
                    nodeType="DSF"
                  />
                </>
              }
            />
            {!isEditable && (
              <SuggestAnEditButton
                nodeType="DSF"
                nodeId={propsId || nodeId}
                isDrawer={isDrawer}
                onClick={onClickSuggest}
                nodeDesc={nodeDesc}
                nodeTitle={nodeTitle}
                marginLeft="39px"
              />
            )}
          </div>
        </div>
        <div className="datasource-field-ref-section">
          <Section
            secTitle={{
              title: "",
              showBorderBottom: false,
              isShowIcon: false,
              paddingBottom: "0px",
            }}
            secRowData={overview?.map((item: SectionRowProps) => ({
              ...item,
              customJSX: dataSourceFieldRefViewRenderer?.[item?.title]?.(item),
            }))}
          />
        </div>
        <div className="datasource-field-ref-section">
          <Section
            secTitle={{
              title: (
                <LineageSectionTitleWithIcon
                  isRefView
                  queryParams={{
                    nodeId: props?.id || nodeId,
                    nodeType: "DSF",
                    nodeName,
                    mode: "drill_down",
                    parentNodeId: tableId,
                    parentNodeName: tableName,
                    parentNodeType: "DSR",
                  }}
                  lineageExists={lineage?.lineageExists}
                />
              ),
              ...titleSecProps,
            }}
            mode="lineage"
            lineageData={{
              ...lineage,
              isRefView: true,
              lineageExists: lineage?.lineageExists,
            }}
            secRowData={[]}
          />
        </div>
      </DataSourceFieldRefViewStyled>
    </StateHandler>
  );
}

function DataSourceFieldRefDrawer(
  props: DataSourceFieldRefViewProps
): JSX.Element {
  return (
    <NodeRefPageHOC
      isDrawer={props?.isDrawer}
      setShowParentGoBack={props?.setShowParentGoBack}
    >
      <Content {...props} />
    </NodeRefPageHOC>
  );
}

export default DataSourceFieldRefDrawer;
