import { SourceTypes } from "../../app.types";
import { useGetAppType } from "../../customhooks";

import {
  categoryIcon,
  chevronRight,
  databaseIcon,
  dataSourceIcon,
  doomianIcon,
  schemaIcon,
} from "../../svgs";

import { getDataSourceLogoSvgByType } from "../../utils/getsourceiconsbytype";
import CoreNodesCircleIcon from "../corenodetypescircleicon";

import StyledLink from "../styledlink/styledlink";
import {
  HeaderItemStyled,
  StyledBreadcrumWrapper,
} from "./styledbreadcrum.styles";

import {
  BreadcrumItemStringType,
  StyledBreadcrumbPropsType,
} from "./styledbreadcrum.types";

import {
  getBreadcrumItemClassName,
  getBreadCrumItemType,
  getBreadcrumItemUrl,
} from "./styledbreadcrum.util";

const dbNameIcon = dataSourceIcon("14", "14");
const termDomainIcon = doomianIcon();
const termCategoryIcon = categoryIcon();
const schemaNameIcon = schemaIcon();

const getBreadcrumItemIcon = (
  typeOfBreadcrum: BreadcrumItemStringType,
  nodeTypeId: SourceTypes
): JSX.Element => {
  const {
    isSrcNameItem,
    isSchemaNameItem,
    isTblNameItem,
    isDbNameItem,
    isTermDmnItem,
    isTermCategoryItem,
    isDsrItem,
  } = getBreadCrumItemType(typeOfBreadcrum);

  if (isSrcNameItem) {
    return getDataSourceLogoSvgByType(nodeTypeId);
  }
  if (isDbNameItem) return dbNameIcon;

  if (isSchemaNameItem) return schemaNameIcon;

  if (isTermDmnItem) return termDomainIcon;

  if (isTermCategoryItem) return termCategoryIcon;

  if (isDsrItem) {
    return (
      <CoreNodesCircleIcon
        nodeType="DSR"
        mode="tertiary"
        width="15px"
        height="15px"
        fontSize="10px"
      />
    );
  }

  if (isTblNameItem) {
    return (
      <CoreNodesCircleIcon
        nodeType="TBL"
        mode="tertiary"
        width="15px"
        height="15px"
      />
    );
  }

  return databaseIcon;
};

const StyledBreadcrum = (props: StyledBreadcrumbPropsType): JSX.Element => {
  const { isExtOrDesktop } = useGetAppType();
  const { items, marginBottom = "20px", isFromHome = false } = props;

  const isAllItemsExists = items?.length === 4;
  const isOnlyOneItemExists = items?.length === 1;

  return (
    <StyledBreadcrumWrapper
      className="StyledBreadcrumWrapper source-config-breadcrum"
      marginBottom={marginBottom}
    >
      {items?.map((item, index) => {
        const {
          title = "",
          srcTypeId = "ORC",
          nodeId = "",
          type = "src",
        } = item;

        const isSrcConnectionItem = !!srcTypeId;

        const breadCrumbItemIcon = getBreadcrumItemIcon(type, srcTypeId);

        const breadcrumItemLink = getBreadcrumItemUrl(type, nodeId);

        const breadcrumItemClassName = getBreadcrumItemClassName(type);

        const { isTermCategoryItem, isTermDmnItem } = getBreadCrumItemType(
          type
        );

        const isSvg =
          isSrcConnectionItem || isTermCategoryItem || isTermDmnItem;

        return (
          <HeaderItemStyled
            key={`${title}-${index}`}
            className={`${breadcrumItemClassName} header-item`}
            isFromHome={isFromHome}
            isAllItemsExists={isAllItemsExists}
            isOnlyOneItemExists={isOnlyOneItemExists}
          >
            <div className={`item-icon ${isSvg ? "svg-icon" : "icon"}`}>
              {breadCrumbItemIcon}
            </div>

            <StyledLink
              to={breadcrumItemLink}
              target={isExtOrDesktop ? "_blank" : "_self"}
              title={title}
              cutomClassName="item-link"
            >
              <div
                title={title}
                className={`item-title ${breadcrumItemLink ? "link-item" : ""}`}
              >
                {title}
              </div>
            </StyledLink>

            {index + 1 < items?.length && (
              <div className="right-icon"> {chevronRight}</div>
            )}
          </HeaderItemStyled>
        );
      })}
    </StyledBreadcrumWrapper>
  );
};
export default StyledBreadcrum;
