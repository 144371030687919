import { useForm } from "react-hook-form";
import { useCallback, useEffect, useMemo } from "react";

import { Skeleton } from "antd";

import { useGetRefByType } from "../../../../../../api/refservice";
import { FormStyled } from "../../../../../../components/form";

import FormItemLabel from "../../../../../../components/form/formitemlabel";
import { ChatAgentFieldStyled } from "./chatagentfield.styles";

import { ChatAgentFieldProps } from "./chatagentfield.types";
import { sortObjectsArrayByKey } from "../../../../../../utils";

import StateHandler from "../../../../../../components/statehandler/statehandler";
import { SelectField } from "../../../../../../components/formfields";

import { KeyValuePairType } from "../../../../../../app.types";

const DEFAULT_CHAT_AGENT_NAME: KeyValuePairType<string> = {
  ACO: "Claude Opus",
  ACS: "Claude Sonnet",
  GPT: "GPT 3.5",
};

const ChatAgentField = (props: ChatAgentFieldProps): JSX.Element => {
  const {
    isEditMode = false,
    aiChatModel = "",
    aiChatModelName = "",
    onChangeAIChatModel,
    setIsFormValid,
  } = props;

  const { control, setError, setValue, watch } = useForm({
    defaultValues: { agentName: aiChatModel },
  });

  const { parsedData: chatModelList, error, isLoading } = useGetRefByType(
    "CADDI_LLM_PROVIDERS"
  );

  const sortedChatAgentsOptions = useMemo(
    () =>
      sortObjectsArrayByKey(chatModelList, "displayOrder")?.map((item) => ({
        label: item?.name,
        value: item?.id,
      })),
    [chatModelList]
  );

  const onChatAgentChange = useCallback(
    (value) => {
      const selectedChatAgentName =
        chatModelList?.find((item) => item?.id === value)?.name || "";
      onChangeAIChatModel(value, selectedChatAgentName);
    },
    [chatModelList, onChangeAIChatModel]
  );

  const selectedChatModelName = useMemo(() => {
    return (
      aiChatModelName ||
      chatModelList?.find((item) => item?.id === aiChatModel)?.name ||
      DEFAULT_CHAT_AGENT_NAME?.[aiChatModel] ||
      "GPT 3.5"
    );
  }, [aiChatModel, chatModelList?.length, aiChatModelName]);

  // Watch the agentName field
  const agentName = watch("agentName");

  useEffect(() => {
    // Set form validity based on the value of agentName
    setIsFormValid?.(agentName !== undefined && agentName?.trim() !== "");
  }, [agentName, setIsFormValid]);

  useEffect(() => {
    setValue("agentName", aiChatModel || "GPT");
  }, [isLoading, isEditMode]);

  return (
    <StateHandler isFetching={false} error={error}>
      <ChatAgentFieldStyled>
        {isEditMode ? (
          <FormStyled isItemColumnLayout>
            <FormItemLabel label="LLM Providers" required paddingLeft="0">
              <SelectField
                placeholder="Select"
                value={aiChatModel}
                options={sortedChatAgentsOptions}
                propOnChange={onChatAgentChange}
                control={control}
                name="agentName"
                setValue={setValue}
                setError={setError}
                isAllowClear={false}
              />
            </FormItemLabel>
          </FormStyled>
        ) : (
          <div className="selected-chat-agent">
            <div className="label">LLM Provider:</div>

            {isLoading ? (
              <Skeleton.Button size="small" active />
            ) : (
              <div className="value">{selectedChatModelName}</div>
            )}
          </div>
        )}
      </ChatAgentFieldStyled>
    </StateHandler>
  );
};

export default ChatAgentField;
