import styled from "styled-components";

export const AddFilterCriteriaFormStyled = styled.div`
  .ant-form-item-control {
    width: 95%;
  }

  .input-field-container {
    width: max-content;
  }
`;

export const FilterCriteriaFormStyled = styled.div`
  padding: 25px;
`;

export const FooterWrapperStyled = styled.div<{ isReadMode: boolean }>`
  display: flex;
  justify-content: ${({ isReadMode }): string =>
    isReadMode ? "space-between" : "flex-end"};
  width: 100%;
  align-items: center;

  .delete-button {
    color: ${({ theme }): string => theme.textColors.RED_100};
    display: flex;
    align-items: center;
    gap: 5px;

    svg {
      margin-bottom: 3px;
    }
  }

  .save-cancel-action {
    display: flex;
    align-items: center;
    column-gap: 5px;
    justify-content: flex-end;
  }
`;
