// <--- Import Statements Start --->
import { parse } from "date-fns";
import * as yup from "yup";

import { MixedSchema } from "yup/lib/mixed";
// <--- Import Statements End --->

// <--- Constants Start --->
import { REQUIRED_MESSAGE } from "../../constants/formconstants";
// <--- Constants End --->

// <--- Types Start --->
import { NodeType } from "../../app.types";
import { FieldMetaDataProps } from "./tabs/settingstab/tabs/connectionsettingstab/connectionsettingstabform/connectionsettingstabform.types";

import {
  SourceAdhocInfoType,
  SourcesParserReturnType,
} from "../../parsers/sources/sourcesparser.types";
// <--- Types End --->

// <--- Utils Start --->
import { getNodeName } from "../../utils";
import { getAlreadyCheckedScanOptions } from "../../components/scantypeform/scantypeform.util";
// <--- Utils End --->

export function getItemValidator(item: FieldMetaDataProps): any {
  const { rules, required, requiredMessage } = item;

  if (!rules) {
    return undefined;
  }

  const validators: yup.BaseSchema[] = rules?.map((item: any) => {
    const validator = yup
      .string()
      ?.trim()
      ?.matches(new RegExp(item?.regex, "g"), item?.message);

    if (required) {
      return validator?.required(requiredMessage || REQUIRED_MESSAGE);
    }

    return validator;
  });

  const validations = validators.reduce(
    (prev: MixedSchema, curr) => prev.concat(curr),
    yup.mixed()
  );

  return (
    validators.length && {
      [item.name]: validations,
    }
  );
}

export function getGeneralSettingsFormDefaultValues(
  parsedData: SourcesParserReturnType,
  srcAdhocInfo?: SourceAdhocInfoType
): any {
  const alreadySelectedScanOptions = srcAdhocInfo
    ? getAlreadyCheckedScanOptions(srcAdhocInfo)
    : [];

  const userSelectedScanOptions =
    parsedData?.scanInfo?.scanOptionsIncluded || [];

  const allSelectedScanOptions = [
    ...alreadySelectedScanOptions,
    ...userSelectedScanOptions,
  ];

  const initialScanOptions = Array.from(new Set(allSelectedScanOptions));

  return {
    name: parsedData?.name || "",
    description: parsedData?.description || "",
    accessInfo: parsedData?.accessInfo || "",
    owner:
      parsedData?.owners?.map((owner: any) =>
        getNodeName(owner?.name, owner?.email, owner?.user_type as NodeType)
      ) || [],
    ...{
      scanType: initialScanOptions || [],
      frequencyType: parsedData?.scanInfo?.frequencyType || undefined,
      interval: parsedData?.scanInfo?.interval,
      isRepeat: parsedData?.scanInfo?.isRepeat,
      repeatAfterXMinutes: parsedData?.scanInfo?.repeatAfterXMinutes || "",
      repeatForXHours: parsedData?.scanInfo?.repeatForXHours || "",
      activationDate: parsedData?.scanInfo?.activationDate || new Date(),
      startOnTime: parsedData?.scanInfo?.startOnTime
        ? parse(parsedData?.scanInfo?.startOnTime, "hh:mm a", new Date())
        : new Date(),
      ends: parsedData?.scanInfo?.endAfterRepeatingXTimes
        ? "after"
        : parsedData?.scanInfo?.endsOnDate
        ? "on"
        : "never",
      emailRecipients:
        parsedData?.emailRecipients
          ?.map((user: any) =>
            getNodeName(user?.userName, user?.userEmail, user?.userType)
          )
          ?.filter((item: any) => item) || [],
      endsOnDate: parsedData?.scanInfo?.endsOnDate,
      endAfterRepeatingXTimes:
        parsedData?.scanInfo?.endAfterRepeatingXTimes || "",
      daysOfMonth: parsedData?.scanInfo?.daysOfMonth || "DOM",
      daysOfWeek: parsedData?.scanInfo?.daysOfWeek || ["MON"],
    },
  };
}
