import { useCallback, useEffect, useMemo, useState } from "react";
import {
  JobDetailWrapperStyled,
  UpdateScheduleModalContentStyled,
  UpdateScheduleModalStyled,
} from "./updateschedulemodal.styles";

import { FormItemStyled, FormStyled } from "../../../form";
import Button from "../../../button";

import { SelectFieldStyled } from "../../../formfields/selectfield/selectfield.styles";

import { redirectIcon } from "../../../../svgs";
import {
  useCancelModal,
  useGetAppState,
  useOpenDrawer,
  useOpenModal,
} from "../../../../customhooks";

import LinkButton from "../../../linkbutton/linkbutton";

import {
  getPageNode,
  jsonParse,
  openNotification,
  selectFilterOption,
} from "../../../../utils";

import { useGetSchedulersList } from "../../../../api/listingsservice";
import StateHandler from "../../../statehandler/statehandler";

import { useRequestWithMethod } from "../../../../api";
import SuccessNotificationMessage from "../../../successnotificationmessagerendrer/successnotificationmessagerendrer";

import {
  NodeFromViewType,
  UpdateScheduleModalContentProps,
  UpdateScheduleModalProps,
} from "./updateschedulemodal.types";
import { AddNewJobModalProps } from "../../../../forms/addnewjobform/addnewjobform.types";

import { JobConfigType } from "../../../../parsers/jobparser/jobparser.types";

import { getJobDetailPageUrl } from "../../../../utils/getjobdetailpageurl";
import StyledLink from "../../../styledlink/styledlink";

const redirectIconBtn = redirectIcon("10", "10");

const UpdateScheduleModalContent = (
  props: UpdateScheduleModalContentProps
): JSX.Element => {
  const {
    modal: { modalProps = {} },
  } = useGetAppState();
  const openDrawer = useOpenDrawer();

  const {
    viewId: searchId,
    nodeType = "RLS",
    countFilter,
    filterModel,
    sortModel,
    isSelectAll,
    handleClearAll,
    populateData,
    selectedRulesWithSourceInfo,
  } = modalProps as UpdateScheduleModalProps;

  const {
    isLoading: nodesFromViewLoading,
    error: nodesFromViewError,
    onExecuteRequest: onNodesFromView,
    data: nodesFromView,
  } = useRequestWithMethod("get_node_from_view", [], true);

  useEffect(() => {
    if (isSelectAll)
      onNodesFromView(
        {
          filterModel,
          countFilter,
          sortModel: [
            { colId: "node_name", sort: nodeType === "RLS" ? "desc" : "asc" },
          ],
        },
        [searchId, nodeType]
      );
  }, [isSelectAll]);

  const { isTablePage, isColumnPage } = getPageNode(nodeType);

  const nodeName = isTablePage ? "table" : "rule";

  const allSelectedNodesIds = selectedRulesWithSourceInfo?.map(
    (node) => node?.node_id
  );

  const { addedJobs, schedulers = [] } = props;

  const defaultValues = addedJobs?.map((item) => `${item?.jobDefId}`) || [];

  const [currentSchedule, setCurrentSchedule] = useState<string[]>(
    defaultValues
  );
  const [hasAnyThingChanged, setHasAnyThingChanged] = useState<boolean>(false);
  const onCancel = useCancelModal();
  const onOpenModal = useOpenModal();

  const onScheduleChange = useCallback((selectedJob: string[]) => {
    setCurrentSchedule(selectedJob);
    setHasAnyThingChanged(selectedJob?.length > 0);
  }, []);

  const onUpdateJobSchedulerSuccess = useCallback(
    (response) => {
      const parsedRes = isTablePage
        ? jsonParse(response?.data?.FN_UPDATE_GOV_TBLS_DATA)
        : isColumnPage
        ? jsonParse(response?.data?.FN_UPDATE_GOV_COLS_DATA)
        : jsonParse(response?.data?.FN_UPDATE_GOV_RLSS_DATA);

      openNotification(
        <SuccessNotificationMessage
          message={`${parsedRes?.total_cnt} ${nodeName}(s) has been added to selected schedule(s).`}
        />
      );

      onCancel();
      handleClearAll?.();
      populateData?.({});
    },
    [nodeName, handleClearAll, populateData]
  );

  const {
    onExecuteRequest: onUpdateJobScheduler,
    isLoading: onUpdatingJobScheduler,
    error: errorInUpdatingJobScheduler,
  } = useRequestWithMethod(
    "update_field_data",
    [nodeType, searchId],
    false,
    onUpdateJobSchedulerSuccess
  );

  const scheduleSelectOptions = useMemo(() => {
    // EXACT SAME THING IS BEING REPLICATED ON NOTIFICATIONS TAB. SEE IF WE CAN MAKE IT REUSABLE
    return (
      schedulers?.map((item) => {
        const jobName = item?.jobName;
        const jobDesc = item?.jobDesc;

        const jobNameAndJobDesc = jobDesc
          ? `${jobName} - ${jobDesc} `
          : jobName;

        return {
          labelText: item?.jobName,
          value: `${item?.jobDefId}`,
          label: (
            <JobDetailWrapperStyled title={jobNameAndJobDesc}>
              <div className="job-id">{jobName}</div>
              <div className="separator">-</div>
              <div className="job-name">{jobDesc}</div>
              <div className="flex-grow" />
              <StyledLink
                cutomClassName="job-link"
                to={getJobDetailPageUrl(String(item?.jobDefId))}
              >
                {redirectIconBtn}
              </StyledLink>
            </JobDetailWrapperStyled>
          ),
        };
      }) || []
    );
  }, [schedulers]);

  const onNewJobCreatedSuccess = useCallback(() => {
    handleClearAll?.();
    populateData?.({});
  }, [populateData]);

  const onCreateNewJobClick = useCallback(() => {
    const { isRulePage, isTablePage } = getPageNode(nodeType);

    const jobCreatedOnNodes = isSelectAll
      ? nodesFromView?.map((item: NodeFromViewType) => ({
          name: item?.NODE_NAME
            ? item?.NODE_NAME || ""
            : item?.NODE_TITLE || "",
          id: item?.NODE_ID,
          parent_name: item?.PARENT_NAME || "",
        }))
      : selectedRulesWithSourceInfo?.map((item) => ({
          name: item?.node_name
            ? item?.node_name || ""
            : item?.business_name || "",
          id: item?.node_id,
          parent_name: item?.parent_name,
        })) || [];

    const jobConfig: JobConfigType = {
      jobMetaDataInfo: {
        jobCreatedOnNodes,
        selectionType: "select_individually",
        jobCategory: isTablePage ? "PRF" : "RLS",
      },
    };

    const check = window.confirm(
      "Changes you made may not be saved. Would you still like to proceed?"
    );

    if (check) {
      onCancel();
      onOpenModal({
        modalId: "add_new_job_modal",
        visible: true,
        modalTitle: "Add Job",
        modalProps: {
          jobFor: isRulePage ? "RLS" : "PRF",
          jobConfig,
          propsOnSave: onNewJobCreatedSuccess,
          // selectedRulesWithSourceInfo,
        } as AddNewJobModalProps,
      });
    }
  }, [
    nodeType,
    selectedRulesWithSourceInfo,
    onNewJobCreatedSuccess,
    isSelectAll,
    nodesFromView,
  ]);

  const onSaveClick = useCallback(() => {
    const updatedScheduler = [
      {
        job_def_ids: currentSchedule,
        job_action: "ADD",
        isSelectAll,
        [nodeType === "TBL"
          ? "tbl_ids"
          : nodeType === "COL"
          ? "col_ids"
          : "rule_ids"]: allSelectedNodesIds,
        filterModel: filterModel || {},
        countFilter: countFilter || "all",
      },
    ];

    onUpdateJobScheduler(updatedScheduler);
  }, [
    nodeType,
    countFilter,
    filterModel,
    isSelectAll,
    currentSchedule,
    allSelectedNodesIds,
  ]);

  return (
    <StateHandler
      isFetching={onUpdatingJobScheduler || nodesFromViewLoading}
      error={errorInUpdatingJobScheduler || nodesFromViewError}
      isModal
    >
      <UpdateScheduleModalStyled>
        <UpdateScheduleModalContentStyled>
          <div className="description">
            These {nodeName}(s) will be scheduled based on the selected job from
            the below list. If you want to add these {nodeName}(s) to a new
            schedule then please{" "}
            <LinkButton
              className="create-new-job-btn"
              onClick={onCreateNewJobClick}
            >
              create a new job.
            </LinkButton>{" "}
          </div>
          <div className="input-label">
            Scheduled Jobs <span className="required">*</span>
          </div>

          <SelectFieldStyled
            mode="multiple"
            getPopupContainer={(triggerNode): HTMLElement =>
              triggerNode?.parentNode as HTMLElement
            }
            value={currentSchedule}
            showAction={["focus"]}
            options={scheduleSelectOptions}
            onChange={(selectedOption): void =>
              onScheduleChange((selectedOption as string[]) || [])
            }
            allowClear
            placeholder="Select"
            filterOption={selectFilterOption}
            showSearch
          />
        </UpdateScheduleModalContentStyled>

        <FormStyled isItemColumnLayout>
          <FormItemStyled className="form-actions-sec">
            <Button id="cancel" width="74px" onClick={onCancel}>
              Cancel
            </Button>
            <Button
              id="primary"
              width="74px"
              marginLeft="8px"
              htmlType="submit"
              onClick={onSaveClick}
              disabled={!hasAnyThingChanged}
            >
              Update
            </Button>
          </FormItemStyled>
        </FormStyled>
      </UpdateScheduleModalStyled>
    </StateHandler>
  );
};

const UpdateScheduleModal = (): JSX.Element => {
  const {
    modal: { modalProps = {} },
  } = useGetAppState();

  const {
    selectedNodeJobIds,
    nodeType = "RLS",
  } = modalProps as UpdateScheduleModalProps;

  const categoryType = nodeType === "TBL" ? "PRF" : "RLS";

  const {
    parsedData: schedulers,
    isFetching: isFetchingSchedulers,
    error: errorSchedulers,
  } = useGetSchedulersList(categoryType);

  const jobIds = selectedNodeJobIds?.map((item) => item?.job_def_id);

  const findAddedJobs = useMemo(() => {
    return schedulers?.filter((sch) => jobIds?.includes(sch?.jobDefId));
  }, [schedulers, selectedNodeJobIds]);

  return (
    <StateHandler
      isFetching={isFetchingSchedulers}
      error={errorSchedulers}
      isModal
    >
      <UpdateScheduleModalContent
        addedJobs={findAddedJobs}
        schedulers={schedulers}
        key={isFetchingSchedulers ? "scheduler-modal" : "add-scheduler-modal"}
      />
    </StateHandler>
  );
};

export default UpdateScheduleModal;
