import styled from "styled-components";

export const ScanTypeWrapperStyled = styled.div<{ marginBottom?: string }>`
  margin-bottom: ${({ marginBottom }): string => marginBottom || "0px"};

  .text {
    font-size: 18px;
    color: ${({ theme }): string => theme.textColors.BLUE_116};
    font-family: OpenSansSemiBold;
    margin-bottom: 5px;
  }

  .desc {
    font-size: 12px;
    color: ${({ theme }): string => theme.textColors.GREY_26};
    margin-bottom: 10px;
  }
`;

export const CheckboxWrapperStyled = styled.div`
  display: flex;
  align-items: center;
  column-gap: 8px;

  .label {
    color: rgb(34, 42, 45);
  }

  .ant-checkbox-group {
    display: flex;
    flex-direction: column;
    gap: 5px;

    .ant-checkbox-disabled.ant-checkbox-checked .ant-checkbox-inner::after {
      border-color: ${({ theme }): string => theme.textColors.WHITE_COLOR};
    }
  }

  .ant-checkbox-wrapper {
    span {
      color: ${({ theme }): string => theme.textColors.GREY_40};
    }
  }
`;
