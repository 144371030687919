import { useEffect, useMemo } from "react";
import { useQueries } from "react-query";
//               <--Service-->
import { useGetAll } from "../baseservice";
//               <--Service-->

//               <--Constants-->
import { API_CONFIG } from "../../constants/apiconfig";
//               <--Constants-->

//               <--Parsers-->
import {
  getParsedLineageData,
  ReturnTypeOfParsedLineageData,
} from "../../parsers";
//               <--Parsers-->

//               <--Services-->
import { CustomReactQueryHookReturnType } from "../services.types";
//               <--Services-->

//               <--Types-->
import { NodeType } from "../../app.types";
import { ActiveLineageTabType } from "../../pages/lineagepage/lineagecomponents/lineagepageheader/lineagepageheader.types";
import { getParsedLineageAlertNodesData } from "../../parsers/lineageparser/lineageparser";
import { LineageAlertNodesParsedType } from "../../parsers/lineageparser/lineageparser.types";
import { appendQueryParamInUrl, fetchRequestWithoutParam } from "../../api";
import { addParentEdgeInfoInColEdges } from "../../pages/lineagepage/lineagepage.utils/helper";
import { jsonStringify, uniqByKey } from "../../utils";
//               <--Types-->

export function useGetLineageData(
  queries: {
    nodeId: string;
    nodeType: NodeType;
    direction: string;
    lineageType: ActiveLineageTabType;
    isDrillDown: string;
  }[],
  isEnabled?: boolean,
  refetchOnMount?: boolean,
  propsOnSuccess?: () => void
): {
  isLoading: boolean;
  isFetching: boolean;
  isFetched: boolean;
  isAnyError: boolean;
  error: any;
  parsedData: ReturnTypeOfParsedLineageData;
} {
  const fetcher = fetchRequestWithoutParam();

  const config = useQueries(
    isEnabled
      ? queries?.map((query) => {
          const params = [
            query?.nodeId,
            query?.nodeType,
            query?.direction,
            query?.lineageType,
            query?.isDrillDown,
          ];
          return {
            queryKey: appendQueryParamInUrl(
              API_CONFIG?.get_lineage_details.url,
              params
            ),
            queryFn: fetcher as any,
            refetchOnMount: !!refetchOnMount,
          };
        })
      : []
  );

  const queriesConfig = useMemo(() => {
    const parsedData = config?.map((con) => {
      const configData = con?.data || {};

      try {
        return getParsedLineageData(configData as any);
      } catch (error) {
        return {
          nodes: [],
          edges: [],
        };
      }
    });

    const allNodes = parsedData?.map((data) => data?.nodes)?.flat();

    const allUniqueNodes = uniqByKey(allNodes, "id");

    const allEdges = parsedData?.map((data) => data?.edges)?.flat();

    const level1Edges = allEdges?.filter((edge) => !edge?.data?.isLevel2Edge);
    const level2Edges = allEdges?.filter((edge) => edge?.data?.isLevel2Edge);

    const finalAllEdges = [...level2Edges, ...level1Edges];

    const allUniqueEdges = uniqByKey(finalAllEdges, "id");

    const allNodesAndEdges = {
      nodes: allUniqueNodes,
      edges: allUniqueEdges,
    };

    const isError = config?.some((con) => con?.error);

    const configErrors = config?.filter((con) => con?.error);

    return {
      isLoading: config?.some((con) => con?.isLoading),
      isFetching: config?.some((con) => con?.isFetching),
      isFetched: config?.every((con) => con?.isFetched),
      error: isError ? configErrors?.[0]?.error : null,
      isAnyError: isError,
      parsedData: allNodesAndEdges,
    };
  }, [jsonStringify(config)]);

  return queriesConfig;
}

export function useGetLineageAlertNodesData(
  nodeId: string,
  nodeType: NodeType
): CustomReactQueryHookReturnType<LineageAlertNodesParsedType> {
  return useGetAll<LineageAlertNodesParsedType>({
    apiConfig: API_CONFIG.get_lineage_alert_nodes,
    parser: getParsedLineageAlertNodesData,
    params: [nodeId, nodeType],
    options: {
      enabled: !!nodeId && !!nodeType,
    },
  });
}
