import styled from "styled-components";
import { ellipsis } from "../../../../../../styles/app.styles";

export const AddWidgetModalStyled = styled.div`
  height: calc(100vh - 98px); ;
`;

export const AddWidgetFormStyled = styled.div`
  max-height: calc(100vh - 108px);
  overflow: auto;
  height: 100%;

  .add-new-filter-criteria-btn {
    font-size: 12px;
  }

  .section-heading {
    font-size: 16px;
    color: ${({ theme }): string => theme.textColors.BLUE_116};
    margin-left: 20px;
    font-family: OpenSansSemiBold;
    margin-bottom: 8px;
  }

  .ant-select-tree-title {
    max-width: 100%;
  }

  .ant-select-selection-item {
    .module-filter-criteria-edit-icon {
      display: none;
    }

    :hover {
      .module-filter-criteria-edit-icon {
        display: none;
      }
    }
  }

  .transfer-comp {
    width: 400px;
  }

  /* .ant-select-tree-switcher {
    width: 0;
  } */
`;

export const FormInputAndChartWrapperStyled = styled.div`
  margin-left: 250px;
  margin-right: 80px;
  margin-top: 50px;

  display: flex;
  align-items: flex-start;
  justify-content: space-between;

  .data-type-icon {
    width: 30px;
  }
`;

export const FormInputWrapperStyled = styled.div`
  display: flex;
  align-items: flex-start;
  flex-direction: column;
`;

export const ChartImageWrapperStyled = styled.div`
  display: flex;
  padding: 40px;
  width: 600px;
  height: 330px;
  align-items: center;
  justify-content: center;
  background-color: ${({ theme }): string => theme.bgColors.LIGHT_GREY_22};

  .img-text-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    row-gap: 10px;
    margin-top: -6px;

    img {
      width: 500px;
    }
  }

  .img-text {
    color: ${({ theme }): string => theme.textColors.GREY_210};
  }
`;

export const ChartNameAndIconWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  column-gap: 6px;

  .icon {
    padding-top: 6px;
    margin: 0;

    svg {
      width: 17px;
      height: 17px;
      path {
        fill: ${({ theme }): string => theme.textColors.GREY_210};
      }
    }
  }

  &.bar {
    .icon {
      svg {
        width: 14px;
        height: 14px;
      }
    }
  }
`;

export const FlexDiv = styled.div`
  flex-grow: 1;
`;

export const FilterNameAndDescWrapperStyled = styled.div`
  display: flex;
  align-items: center;
  column-gap: 5px;
  justify-content: space-between;

  .name-desc-wrapper {
    column-gap: 5px;
    display: flex;
    align-items: center;
    max-width: 370px;

    .name {
      ${ellipsis}
    }

    .desc {
      /* width: 100%; */
      color: ${({ theme }): string => theme.textColors.GREY_210};
      ${ellipsis};
      max-width: 130px;
    }
  }

  .module-filter-criteria-edit-icon {
    align-items: flex-end;
    display: none;
    margin-right: 8px;
  }

  .module-filter-criteria-eye-icon {
    margin-right: 8px;
  }

  :hover {
    .module-filter-criteria-edit-icon {
      display: inline-block;
    }
  }

  .eyeicon-svg {
    fill: #428bca;
    /* stroke: black; */
  }

  .eyeicon-svg {
    fill: white;
    /* stroke: black; */
  }
`;
