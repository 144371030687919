import { Tooltip } from "antd";
import { useCallback, useMemo } from "react";
import { useFormContext } from "react-hook-form";

import {
  CheckboxWrapperStyled,
  ScanTypeWrapperStyled,
} from "./scantypeform.styles";

import { ScanTypeFormProps } from "./scantypeform.type";

import { DVSUM_TOOLTIP_CLASS_NAME, ELEMENT_IDS } from "../../constants";
import Checkbox from "../checkbox";

import {
  getAvailableScanTypeOptions,
  getScanDescription,
} from "./scantypeform.util";

export const ScanTypeForm = (props: ScanTypeFormProps): JSX.Element => {
  const { srcAdhocInfo, secMarginBottom = "" } = props;

  const { setValue, watch, formState, getValues } = useFormContext();

  const {
    datasrc_scan_lineage_btn: DATASRC_SCAN_LINEAGE_BTN,
    datasrc_scan_profile_btn: DATASRC_SCAN_PROFILE_BTN,
  } = ELEMENT_IDS;

  const scanOptionsToDisplay = useMemo(() => {
    return getAvailableScanTypeOptions(srcAdhocInfo);
  }, [srcAdhocInfo]);

  const watchScanType = watch("scanType");

  const onCheckBoxChange = useCallback(
    (e) => {
      const currentValue = e?.target?.value || "";

      const existingScanType: string[] = watchScanType?.length
        ? watchScanType
        : [];

      if (!existingScanType?.includes(currentValue)) {
        setValue("scanType", [...existingScanType, currentValue], {
          shouldValidate: true,
          shouldDirty: true,
          shouldTouch: true,
        });
      } else {
        const updatedScanType = existingScanType?.filter(
          (item) => item !== currentValue
        );

        setValue("scanType", [...updatedScanType], {
          shouldValidate: true,
          shouldDirty: true,
          shouldTouch: true,
        });
      }
    },
    [watchScanType]
  );

  return (
    <ScanTypeWrapperStyled marginBottom={secMarginBottom}>
      <p className="text">Scan Catalog</p>

      <p className="desc">{getScanDescription(srcAdhocInfo)}</p>
      {scanOptionsToDisplay?.map((option) => {
        const isLineageSection = option?.value === "LIN";

        const sectionId = isLineageSection
          ? DATASRC_SCAN_LINEAGE_BTN
          : DATASRC_SCAN_PROFILE_BTN;

        const isOptChecked =
          watchScanType?.includes(option?.value) || option?.checked;

        return (
          <CheckboxWrapperStyled key={option?.value} id={sectionId}>
            <Tooltip
              overlayClassName={DVSUM_TOOLTIP_CLASS_NAME}
              title={
                option?.disabled
                  ? "This setting is pre-selected and cannot be changed for catalog compatibility."
                  : ""
              }
              placement="left"
            >
              <Checkbox
                {...option}
                name="scanType"
                checked={isOptChecked}
                onChange={onCheckBoxChange}
              >
                <label className="label">{option?.label}</label>
              </Checkbox>
            </Tooltip>
          </CheckboxWrapperStyled>
        );
      })}
    </ScanTypeWrapperStyled>
  );
};

export default ScanTypeForm;
