import { useCallback, useEffect, useMemo, useState } from "react";
import { useFormContext } from "react-hook-form";

import FormItemLabel from "../../../components/form/formitemlabel/formitemlabel";
import {
  RadioGroupField,
  SelectField,
  TextAreaField,
} from "../../../components/formfields";

import { SectionContainerStyled } from "../addnewjobform.styles";

import TagsEditor from "../../../components/tagseditor/tagseditor";
import { TransferRecordType } from "../../../components/transfer/transfer.types";

import { AddNewJobFormType } from "../addnewjobform.types";
import { getDvSumInformativeText } from "../../../utils";

import { CoreNodesCircleIcon } from "../../../components";
import {
  useGetSearchedNodeCat,
  useGetSelectedNodeData,
} from "../../../api/ruleservice";

import Transfer from "../../../components/transfer";
import { useDebounce } from "../../../customhooks";

import { JobDefinitionFormType } from "./addjobdefinitionform.types";

import {
  RightTitleWrapperStyled,
  TransferCompStyled,
} from "./addjobdefinitionform.styles";

import Flex from "../../../components/flex";
import { databaseIcon, schemaIcon } from "../../../svgs";

import { getModuleLevelAccessInfo } from "../../../utils/getModuleLevelAccessInfo";
import { getDataSourceLogoSvgByType } from "../../../utils/getsourceiconsbytype";

const schemaNameIcon = schemaIcon();

export const AddJobDefinitionForm = ({
  state,
  setState,
  tagsDataState,
  setTagsDataState,
  appliedTags,
  initialTransferState,
  isEdit,
  jobFor,
  addToJobLimit = 0,
  jobCreatedOnNodes = [],
}: JobDefinitionFormType): JSX.Element => {
  const [searchValue, setSearchValue] = useState("");

  const { control, setValue, watch } = useFormContext();
  const { jobCategory } = watch();

  const isScanCatalog = jobCategory === "CTG";

  const node = jobCategory === "PRF" ? "TBL" : "RLS";

  const jobCreatedOnNodesIds = jobCreatedOnNodes?.map((node) => node?.id || 0);

  const {
    parsedData: selectedNodeData = [],
    isFetching: isFetchingSelectedNodeData,
    error: selectedNodeDataError,
  } = useGetSelectedNodeData(node, jobCreatedOnNodesIds);

  const {
    parsedData: parsedNodeData = [],
    isLoading: isLoadingNodeData,
    error: parsedNodeDataError,
    refetch,
  } = useGetSearchedNodeCat(node, searchValue, !isScanCatalog, true);

  const searchDebounce = useDebounce(searchValue, 500);

  useEffect(() => {
    searchDebounce && refetch();
  }, [searchDebounce]);

  // const {
  //   parsedData: sourcesList = [],
  //   isLoading: sourcesListLoading,
  //   error: sourcesListError,
  // } = useGetAllDataSources(isScanCatalog);

  // const sortedSourcesList = useMemo(
  //   () => sortObjectsArrayByKey(sourcesList, "name"),
  //   [sourcesList]
  // );

  // const sourceSelectOptions = useMemo(
  //   () =>
  //     sortedSourcesList?.map((source) => ({
  //       value: `${source?.id}`,
  //       label: (
  //         <SourceNameAndTypeWrapperStyled>
  //           <span className="icon">
  //             {getDataSourceLogoIconByType(source?.sourceTypeId)}
  //           </span>
  //           <span className="label">{source?.name}</span>
  //         </SourceNameAndTypeWrapperStyled>
  //       ),
  //       labelText: source?.name,
  //     })) || [],
  //   [sortedSourcesList]
  // );

  const [showSecondScreen, setShowSecondScreen] = useState(false);

  const onJobCategoryChange = useCallback(
    (option: string) => {
      setValue("scheduleType", "SCH");
      setValue("source", "");
      setSearchValue("");

      if (option === "RLS") {
        setState(initialTransferState);
      } else {
        setState({ data: [], targetKeys: [] });
      }
    },
    [initialTransferState]
  );

  const isTransferDataLoading = isLoadingNodeData || isFetchingSelectedNodeData;

  const allTransferData = isTransferDataLoading
    ? []
    : [...selectedNodeData, ...parsedNodeData];

  const transferDataParsed = useMemo(
    () => ({
      ...state,

      data: [
        ...state?.data?.filter((val) => state?.targetKeys?.includes(val?.key)),
        ...allTransferData?.map((val) => ({
          ...val,
          description: jobCategory === "RLS" ? val?.description : "",
          name:
            jobCategory === "RLS"
              ? val?.name
              : `${val?.parentName} ${val?.name}`,
          icon: isScanCatalog ? (
            <span />
          ) : jobCategory === "RLS" ? (
            <CoreNodesCircleIcon
              nodeType={node}
              width="20px"
              height="20px"
              marginRight="4px"
              fontSize="10px"
            />
          ) : (
            <>
              <TransferCompStyled>
                <Flex customClass="source" columnGap={2}>
                  {getDataSourceLogoSvgByType(val?.sourceTypeId)}

                  <span className="text" title={val?.parentName}>
                    {val?.parentName}
                  </span>
                </Flex>
                <div className="arrow">{">"}</div>

                <Flex customClass="source" columnGap={2}>
                  <span className="icon">{databaseIcon}</span>

                  <span className="text" title={val?.dbName}>
                    {val?.dbName}
                  </span>
                </Flex>
                <div className="arrow">{">"}</div>

                <Flex customClass="source" columnGap={2}>
                  <span className="icon">{schemaNameIcon}</span>

                  <span className="text" title={val?.schemaName}>
                    {val?.schemaName}
                  </span>
                </Flex>
                <div className="arrow">{">"}</div>

                <Flex customClass="name">
                  <CoreNodesCircleIcon
                    nodeType={node}
                    width="15px"
                    height="15px"
                    marginRight="4px"
                    fontSize="10px"
                  />
                  <span className="text" title={val?.name}>
                    {val?.name}
                  </span>
                </Flex>
              </TransferCompStyled>
            </>
          ),
        })),
      ],
    }),
    [allTransferData, state]
  );

  const transferData = useMemo(() => {
    return isFetchingSelectedNodeData
      ? []
      : transferDataParsed?.data?.map((item: TransferRecordType) => {
          return {
            ...item,
            icon: item?.icon,
            description: state.targetKeys?.includes(item?.key)
              ? ""
              : item?.description,
          };
        });
  }, [state?.targetKeys, isFetchingSelectedNodeData, transferDataParsed]);

  const {
    datdict_addrule_def_sel_type_base_on_attr: {
      description: datdictAddruleDefSelTypeBaseOnAttr = "",
    } = {},
    datdict_addrule_def_sel_type_sel_indiv: {
      description: datdictAddruleDefSelTypeSelIndiv = "",
    } = {},
  } = getDvSumInformativeText();

  const rightTitle = (
    <RightTitleWrapperStyled>
      Selected
      <span className="selection-count">
        (Maximum {addToJobLimit} can be added)
      </span>
    </RightTitleWrapperStyled>
  );

  const { isDqEnabled } = getModuleLevelAccessInfo();

  const resetSeachValue = useCallback(() => {
    setSearchValue("");
  }, []);

  const isTransferDataError = parsedNodeDataError || selectedNodeDataError;

  return (
    <>
      <FormItemLabel label="Job Category" paddingLeft="0" required>
        <SelectField
          setValue={setValue}
          isAllowClear={false}
          control={control}
          name="jobCategory"
          allowClear={false}
          placeholder="Select"
          options={[
            ...((isDqEnabled && [{ label: "Execute Rules", value: "RLS" }]) ||
              []),
            { label: "Profile Tables", value: "PRF" },
          ]}
          width="552px"
          propOnChange={(option: string): void => onJobCategoryChange(option)}
          disabled={!!jobFor}
        />
      </FormItemLabel>

      <FormItemLabel label="Description" marginTop="26px" paddingLeft="0">
        <TextAreaField control={control} name="desc" />
      </FormItemLabel>

      {!isScanCatalog && jobCategory && (
        <SectionContainerStyled>
          <span className="section-title">Selection Type</span>

          <RadioGroupField
            control={control}
            name="selectionType"
            options={[
              {
                label: "Based on Attributes",
                value: "based_on_attributes",
                labelDesc: datdictAddruleDefSelTypeBaseOnAttr,
                // Need to do with a props in schedulers epic
                disabled: isEdit,
                children: (
                  <div className="tags-editor">
                    <TagsEditor
                      tagsDataState={tagsDataState}
                      setTagsDataState={setTagsDataState}
                      appliedTags={appliedTags}
                      showSecondScreen={showSecondScreen}
                      setShowSecondScreen={setShowSecondScreen}
                    />
                  </div>
                ),
              },
              {
                label: "Select Individually",
                value: "select_individually",
                labelDesc: datdictAddruleDefSelTypeSelIndiv,
                children: (
                  <div className="transfer">
                    <Transfer
                      state={{ ...state, data: [...transferData] }}
                      setState={setState}
                      leftTile="Available"
                      rightTile={rightTitle}
                      showUpDownComp
                      maxItemsInTarget={addToJobLimit}
                      getSearchValue={setSearchValue}
                      loading={isTransferDataLoading}
                      notFoundMessage="No records found"
                    />
                  </div>
                ),
              },
            ]}
            direction="column"
            labelDescWidth="512px"
            gap={6}
            propOnChange={resetSeachValue}
          />
        </SectionContainerStyled>
      )}
    </>
  );
};
