import { SpecifyCriteriaType } from "../../components/criteriaselection/criteriaselection.types";
import { MONTH_DAY_YEAR_HOUR_MINUTE } from "../../constants";
import { apiToFormCriteria } from "../../forms/addgovernanceviewform/addgovernanceviewform.utils";
import { isJsonString, utcTOLocalTimeZone } from "../../utils";
import {
  FilterCriteriaParsedType,
  FilterCriteriaResponseType,
  FilterResponseType,
  LogicalOperator,
} from "../tablepage/tablepageparser.types";
import {
  ParsedWorkflowType,
  RuleClassificationType,
  RuleDefinitionValueType,
  RuleSetLocalStateDataType,
  RuleWorkflowType,
} from "./ruleparser.types";

export const DATE_FORMAT = "DD/MM/YYYY hh:mm A";

export const getWorkflow = (workflow: RuleWorkflowType): ParsedWorkflowType => {
  const isRuleExecuted = workflow?.rule_exec_by_id;
  const isRuleAssigned = workflow?.rule_assigned_to_id;

  const actionTile = isRuleExecuted ? "Executed By" : "Assigned to";

  const actionTitleVerb = isRuleExecuted ? "Executed" : "Assigned";

  const isCompleted = isRuleExecuted || isRuleAssigned;

  const actionBy = isRuleExecuted
    ? workflow?.rule_exec_by
    : workflow?.rule_assigned_to;

  const actionAt = isRuleExecuted
    ? workflow?.rule_exec_on
    : workflow?.rule_assigned_on;

  return [
    {
      stepDescription: `${actionTile} ${actionBy} at ${utcTOLocalTimeZone(
        actionAt,
        MONTH_DAY_YEAR_HOUR_MINUTE
      )}`,

      stepName: actionTitleVerb,
      stepNum: 1,
      stepStatus: isCompleted ? "CMP" : "INP",
      stepType: isRuleExecuted ? "EXC" : "ASN",
    },

    {
      stepDescription: workflow?.rule_ack_by
        ? `Acknowledged by ${workflow?.rule_ack_by} at ${utcTOLocalTimeZone(
            workflow?.rule_ack_on,
            MONTH_DAY_YEAR_HOUR_MINUTE
          )}`
        : "",
      stepName: "Acknowledged",
      stepNum: 2,
      stepStatus: workflow?.rule_ack_by_id ? "CMP" : "INP",
      stepType: "ACK",
    },
    {
      stepDescription: workflow?.rule_rsld_by
        ? `Resolved by ${workflow?.rule_rsld_by} at ${utcTOLocalTimeZone(
            workflow?.rule_rsld_on,
            MONTH_DAY_YEAR_HOUR_MINUTE
          )}`
        : "",
      stepName: "Resolved",
      stepNum: 3,
      stepStatus: workflow?.rule_rsld_by_id ? "CMP" : "INP",
      stepType: "RSL",
    },
  ];
};

export const getWorkflowStats = (
  workflow: RuleWorkflowType
): { currentWorkflowStatus: string; actionPerformedBy: string } => {
  const currentWorkflowStatus = workflow?.rule_action_status_id;

  const actionPerformedBy =
    currentWorkflowStatus === "ACK"
      ? workflow?.rule_ack_by
      : currentWorkflowStatus === "RSL"
      ? workflow?.rule_rsld_by
      : workflow?.rule_assigned_to;

  return { currentWorkflowStatus, actionPerformedBy };
};

export const extractClassificationTagsFromApiData = (
  classificationData: RuleClassificationType
): string[] => {
  return (
    classificationData?.tags?.flatMap((tagSet) =>
      tagSet?.value?.map((tag) => {
        const tagNameWithTagset = tag?.tag_name || "";
        // Find the first dot and split accordingly
        const firstDotIndex = tagNameWithTagset.indexOf(".");
        const tagName =
          firstDotIndex !== -1
            ? tagNameWithTagset.substring(firstDotIndex + 1)
            : tagNameWithTagset; // If no dot is present, use the entire string

        return `${tagSet?.tagset_name || ""} > ${tagName}`;
      })
    ) || []
  );
};

export const apiFiltersToFormValues = (
  ruleFilters: FilterCriteriaResponseType
): Array<{
  condition: SpecifyCriteriaType[];
  next_operator: LogicalOperator;
}> => {
  return (ruleFilters || [])?.flatMap((filterCriteriaRow) => {
    const apiToFormParsedData = apiToFormCriteria(
      filterCriteriaRow?.condition,
      []
    );

    return {
      condition: apiToFormParsedData,
      next_operator: filterCriteriaRow?.next_operator,
    };
  });
};

// MAKING UTIL OF ALREADY EXISTING CODE. IT MIGHT NEED SOME CHANGES
export const convertApiDataOfRulesetIntoStateData = (
  apiDataValues: RuleDefinitionValueType,
  convertFiltersToStateData: boolean
): RuleSetLocalStateDataType => {
  return {
    blanks: Object.keys(apiDataValues)
      ?.filter((key) => key?.includes("BLK"))
      ?.map((filteredKeys) => {
        const ruleFilters: FilterCriteriaResponseType =
          apiDataValues?.[filteredKeys]?.col_filter;

        const isFilterExists = ruleFilters?.[0]?.condition?.length;

        const parsedFilters = isFilterExists
          ? apiFiltersToFormValues(ruleFilters)
          : [];

        return {
          colId: apiDataValues?.[filteredKeys]?.col_id,
          filterCondition: convertFiltersToStateData
            ? parsedFilters
            : ruleFilters,
        };
      }),

    dataFormat: Object.keys(apiDataValues)
      ?.filter((key) => key?.includes("DAF"))
      ?.map((filteredKeys) => {
        const ruleFilters: FilterCriteriaResponseType =
          apiDataValues?.[filteredKeys]?.col_filter;

        const isFilterExists = ruleFilters?.[0]?.condition?.length;

        const parsedFilters = isFilterExists
          ? apiFiltersToFormValues(ruleFilters)
          : [];

        return {
          colId: apiDataValues?.[filteredKeys]?.col_id,
          isWhitespacesAllowed:
            apiDataValues?.[filteredKeys]?.is_whitespaces_allowed,
          dataType: apiDataValues?.[filteredKeys]?.data_type,
          minLength: apiDataValues?.[filteredKeys]?.min_length,
          maxLength: apiDataValues?.[filteredKeys]?.max_length,
          maxPrecision: apiDataValues?.[filteredKeys]?.max_precision,
          filterCondition: convertFiltersToStateData
            ? parsedFilters
            : ruleFilters,
        };
      }),

    valueRange: Object.keys(apiDataValues)
      ?.filter((key) => key.includes("VLR"))
      ?.map((filteredKeys) => {
        const ruleFilters: FilterCriteriaResponseType =
          apiDataValues?.[filteredKeys]?.col_filter;

        const isFilterExists = ruleFilters?.[0]?.condition?.length;

        const parsedFilters = isFilterExists
          ? apiFiltersToFormValues(ruleFilters)
          : [];

        return {
          colId: apiDataValues?.[filteredKeys]?.col_id,
          isSelectionValid: apiDataValues?.[filteredKeys]?.is_selection_valid,

          maxOper: apiDataValues?.[filteredKeys]?.max_oper,
          minOper: apiDataValues?.[filteredKeys]?.min_oper,

          maxExpr: apiDataValues?.[filteredKeys]?.max_expr,
          minExpr: apiDataValues?.[filteredKeys]?.min_expr,

          maxValueType: apiDataValues?.[filteredKeys]?.max_value_type,
          minValueType: apiDataValues?.[filteredKeys]?.min_value_type,

          rangeType: apiDataValues?.[filteredKeys]?.range_type,
          maxValue: apiDataValues?.[filteredKeys]?.max_value,
          minValue: apiDataValues?.[filteredKeys]?.min_value,

          minColId: apiDataValues?.[filteredKeys]?.min_col_id,
          maxColId: apiDataValues?.[filteredKeys]?.max_col_id,

          isBlanksIncluded: apiDataValues?.[filteredKeys]?.is_blanks_included,
          isSingleValue: apiDataValues?.[filteredKeys]?.is_single_value,

          filterCondition: convertFiltersToStateData
            ? parsedFilters
            : ruleFilters,
        };
      }),
  };
};

export const getFiltersAndFilterTextFormApi = (item: {
  RULE_FILTER_CONFIG: string;
  RULE_REF_FILTER_CONFIG: string;
}): { parsedFilters: FilterCriteriaParsedType; filterText: string } => {
  const filters: FilterResponseType = isJsonString(
    item?.RULE_FILTER_CONFIG || ""
  )
    ? JSON.parse(item?.RULE_FILTER_CONFIG)
    : isJsonString(item?.RULE_REF_FILTER_CONFIG || "")
    ? JSON.parse(item?.RULE_REF_FILTER_CONFIG)
    : [];

  const isFilterExists = filters?.filter?.[0]?.condition?.length;

  const parsedFilters = isFilterExists
    ? (filters?.filter || [])?.flatMap((filterCriteriaRow) => {
        const apiToFormParsedData = apiToFormCriteria(
          filterCriteriaRow?.condition,
          []
        );

        return {
          condition: apiToFormParsedData,
          next_operator: filterCriteriaRow?.next_operator,
        };
      })
    : [];

  return { parsedFilters, filterText: filters?.filter_text };
};
