import { AxiosResponse } from "axios";
import { NodeSubType } from "../../app.types";
import { Actions } from "../../components/additionalinfo/additionalinfo.types";
import { MONTH_DAY_YEAR_HOUR_MINUTE } from "../../constants";
import { DATA_SOURCE_SECTION_TITLES } from "../../constants/labelconstants";

import {
  formatUtcToLocalDate,
  getObjectKeys,
  getProminentTags,
  getTimeDifference,
  jsonParse,
} from "../../utils";

import { utcTOLocalTimeZone } from "../../utils/utctolocaltimezone";
import { DataConnectionFields } from "../columnparsers";

import {
  getClassificationCustomTags,
  getLineagePreviewData,
  parseAdditionalInfoData,
} from "../parserutils";
import { GlossaryCategoriesResponse } from "../termsparser/termsparser.types";

import {
  DataSourceDocResponse,
  DataSourceNodeResponseType,
  ParsedDataSourceDocForDetailPage,
  ProminentTagsSimilarNodes,
  ReportFieldsPagesType,
  ReportFieldsResponse,
  RetunTypeOfReportPagesParser,
  ReturnTypeOfDataSouurceParser,
  ReturnTypeOfGetParsedGlossaryCategoriesForSelection,
  ReturnTypeOfParsedReportFields,
  SimilarNodesParserReturnType,
  SimilarNodesResponse,
  WorkSheets,
  WorkSheetsResponse,
} from "./datasourcesparsers.types";

const {
  description,
  node_url: NODE_URL,
  usability_score: USABILITY_SCORE,
  last_refreshed_on: LAST_REFRESHED_ON,
} = DATA_SOURCE_SECTION_TITLES.overview;

const {
  format: FORMAT,
  functional_area: FUNCTIONAL_AREA,
  sub_format: SUB_FORMAT,
  entity: ENTITY,
} = DATA_SOURCE_SECTION_TITLES.classification;

const {
  audit_trail: AUDIT_TRAIL,
  data_owners: DATA_OWNERS,
  data_stewards: DATA_STEWARDS,
  system_owners: SYSTEM_OWNERS,
  system_administrator: SYSTEM_ADMINISTRATOR,
} = DATA_SOURCE_SECTION_TITLES.managed_by;

export function dataSourceRefNodeDocParser({
  data,
}: AxiosResponse<DataSourceNodeResponseType>): ReturnTypeOfDataSouurceParser {
  const isReportType = data?.node_sub_type === "RPT";
  const functionalAreaName =
    data?.classifications?.functional_area?.value?.functional_area_name || "";

  const createdObj = data?.description?.audit_trail?.find(
    (item) =>
      item?.activity === DATA_SOURCE_SECTION_TITLES?.audit_trail?.created
  );
  const updatedObj = data?.description?.audit_trail?.find(
    (item) =>
      item?.activity === DATA_SOURCE_SECTION_TITLES?.audit_trail?.updated
  );

  const entityName = data?.classifications?.entity?.value?.entity_title || "";
  const entityId = data?.classifications?.entity?.value?.entity_id || "";

  const classificationData =
    getClassificationCustomTags(data?.classifications) || [];

  const lastRefreshedDate = getTimeDifference(
    utcTOLocalTimeZone(data?.last_refreshed_on || "")
  );

  return {
    node_id: `${data?.node_id || ""}`,
    node_name: data?.node_name || "",
    source_id: `${data?.parent_id || ""}`,
    source_name: data?.parent_name || "",
    publish_status: data?.published_status || "",
    publish_status_id: data?.published_status_id,
    is_editable: !!data?.is_editable,
    node_desc: data?.description?.description?.value || "",
    node_title: data?.title?.value || "",
    last_refreshed: lastRefreshedDate,
    is_wf_enabled: !!data?.is_wf_enabled,
    db_name: data?.db_name || "None",
    schema_name: data?.folder_name || "None",
    src_type_id: data?.src_type_id,

    prominentTags: {
      tags: getProminentTags(classificationData),
      isBoldView: true,
    },
    overview: [
      {
        title: USABILITY_SCORE,
        value: `${data?.description?.metadata_score?.value || ""}`,
      },
      {
        title: description,
        value: data?.description?.description?.value || "",
      },
      { title: NODE_URL, value: data?.node_url || "" },
      { title: LAST_REFRESHED_ON, value: lastRefreshedDate },
      {
        title: FUNCTIONAL_AREA,
        value: functionalAreaName,
      },
      {
        title: ENTITY,
        value: entityName,
        selectedValues: [`${entityId}`],
      },
    ],
    additional_info: parseAdditionalInfoData(data?.additional_info),
    classification: [
      {
        title: FORMAT,
        value: data?.classifications?.format || "",
      },
      {
        title: SUB_FORMAT,
        value: data?.classifications?.subformat || "",
      },

      ...classificationData,
    ],
    lineage: getLineagePreviewData(data?.lineage),
    managed_by: [
      {
        title: DATA_OWNERS,
        value: "",
        keyValueTags: data?.managed_by?.data_owners?.value
          ?.map((item) => ({
            name:
              item?.user_type === "USR"
                ? `${item?.data_owner_name || ""} (${
                    item?.data_owner_email || ""
                  })`
                : item?.data_owner_name,
            type: item?.user_type,
            email: item?.data_owner_email || "",
          }))
          ?.filter((item) => item?.name),
        isInherited: data?.managed_by?.data_owners?.value?.some(
          (item) => item?.is_inherited
        ),
        inHeritedBy:
          data?.managed_by?.data_owners?.value?.find(
            (item) => item?.is_inherited
          )?.inherited_from || "",
      },
      {
        title: isReportType ? SYSTEM_OWNERS : SYSTEM_ADMINISTRATOR,
        value: "",
        keyValueTags: data?.managed_by?.system_owners?.value
          ?.map((item) => ({
            name:
              item?.user_type === "USR"
                ? `${item?.system_owner_name || ""} (${
                    item?.system_owner_email || ""
                  })`
                : item?.system_owner_name,
            type: item?.user_type,
            email: item?.system_owner_email || "",
          }))
          ?.filter((item) => item?.name),
        isInherited: data?.managed_by?.system_owners?.value?.some(
          (item) => item?.is_inherited
        ),
        inHeritedBy:
          data?.managed_by?.system_owners?.value?.find(
            (item) => item?.is_inherited
          )?.inherited_from || "",
      },
      {
        title: DATA_STEWARDS,
        value: "",
        keyValueTags: data?.managed_by?.data_stewards?.value
          ?.map((item) => ({
            name:
              item?.user_type === "USR"
                ? `${item?.data_steward_name || ""} (${
                    item?.data_steward_email || ""
                  })`
                : item?.data_steward_name,
            type: item?.user_type,
            email: item?.data_steward_email || "",
          }))
          ?.filter((item) => item?.name),
        isInherited: data?.managed_by?.data_stewards?.value?.some(
          (item) => item?.is_inherited
        ),
        inHeritedBy:
          data?.managed_by?.data_stewards?.value?.find(
            (item) => item?.is_inherited
          )?.inherited_from || "",
      },
      {
        title: AUDIT_TRAIL,
        value: `${createdObj?.activity || ""} by ${
          createdObj?.user_name || ""
        } ${getTimeDifference(
          utcTOLocalTimeZone(createdObj?.activity_on || "")
        )}:${updatedObj?.activity || ""} by ${
          updatedObj?.user_name || ""
        } ${getTimeDifference(
          utcTOLocalTimeZone(updatedObj?.activity_on || "")
        )}`,
      },
    ],
  };
}

export function getParsedDataSourceDocForDetailPage({
  data,
}: AxiosResponse<DataSourceDocResponse>): ParsedDataSourceDocForDetailPage {
  const isReportType = data?.node_sub_type === "RPT";
  const createdObj = data?.description?.audit_trail?.find(
    (item) =>
      item?.activity === DATA_SOURCE_SECTION_TITLES?.audit_trail?.created
  );
  const updatedObj = data?.description?.audit_trail?.find(
    (item) =>
      item?.activity === DATA_SOURCE_SECTION_TITLES?.audit_trail?.updated
  );
  const functionalAreaName =
    data?.classifications?.functional_area?.value?.functional_area_name || "";
  const functionalAreaId =
    data?.classifications?.functional_area?.value?.functional_area_id;

  const entityId = data?.classifications?.entity?.value?.entity_id || "";
  const entityTitle = data?.classifications?.entity?.value?.entity_title || "";

  return {
    node_name: data?.node_name || "",
    node_desc: data?.node_desc || "",
    node_type: data?.node_type || "DSR",
    node_sub_type: data?.node_sub_type || "DSR",
    node_url: data?.node_url || "",
    is_deprecated: data?.published_status_id === "DEP",
    is_draft_exist: data?.is_draft_exists,
    is_editable: !!data?.is_editable,
    is_endorsed: data?.endorsed_status?.value,
    source_name: data?.parent_name || "",
    endorsed_count: data?.endorsed_count || 0,
    get_access_info: data?.get_access_info?.value || "",
    classification: {
      data: [
        {
          title: DATA_SOURCE_SECTION_TITLES?.classification?.format,
          value: data?.classifications?.format || "",
        },
        {
          title: DATA_SOURCE_SECTION_TITLES?.classification?.sub_format,
          value: data?.classifications?.subformat || "",
        },

        ...getClassificationCustomTags(data?.classifications || {}),
      ],
      isEdited: false,
      editable: false,
    },
    additional_info: {
      isEdited: false,
      isEditable: false,
      data: [
        ...(data?.additional_info?.value || [])
          ?.filter((item) => item?.Link)
          ?.map((item, index) => ({
            actionId: "embed_link" as Actions,
            id: `embed_link-${index}`,
            value: item?.Link || "",
          })),
        ...(data?.additional_info?.value || [])
          ?.filter((item) => item?.Text)
          ?.map((item, index) => ({
            actionId: "edit" as Actions,
            id: `edit-${index}`,
            value: item?.Text || "",
          })),
      ],
    },
    lineage: getLineagePreviewData(data?.lineage),
    managed_by: {
      isEdited: false,
      editable: false,
      data: [
        {
          title: DATA_SOURCE_SECTION_TITLES?.managed_by?.data_owners,
          keyValueTags: data?.managed_by?.data_owners?.value
            ?.map((item) => ({
              name:
                item?.user_type === "USR"
                  ? `${item?.data_owner_name || ""} (${
                      item?.data_owner_email || ""
                    })`
                  : item?.data_owner_name,
              type: item?.user_type,
              email: item?.data_owner_email || "",
            }))
            ?.filter((item) => item?.name && item?.name?.trim() !== "()"),
          type: "multiselect",
          isEditable: true,
          selectedValues: data?.managed_by?.data_owners?.value
            ?.map((item) =>
              item?.user_type === "USR"
                ? `${item?.data_owner_name || ""} (${
                    item?.data_owner_email || ""
                  })`
                : item?.data_owner_name
            )
            ?.filter((item) => item && item?.trim() !== "()"),
          apiKey: "get_user_users_groups",
          urlParam: ["", "USR,UGP"],
          isInherited: data?.managed_by?.data_owners?.value?.some(
            (item) => item?.is_inherited
          ),
          inHeritedBy:
            data?.managed_by?.data_owners?.value?.find(
              (item) => item?.is_inherited
            )?.inherited_from || "",
        },
        {
          title: isReportType
            ? DATA_SOURCE_SECTION_TITLES?.managed_by?.system_owners
            : DATA_SOURCE_SECTION_TITLES?.managed_by?.system_administrator,
          keyValueTags: data?.managed_by?.system_owners?.value
            ?.map((item) => ({
              name:
                item?.user_type === "USR"
                  ? `${item?.system_owner_name || ""} (${
                      item?.system_owner_email || ""
                    })`
                  : item?.system_owner_name,
              type: item?.user_type,
              email: item?.system_owner_email || "",
            }))
            ?.filter((item) => item?.name && item?.name?.trim() !== "()"),
          type: "multiselect",
          isEditable: true,
          selectedValues: data?.managed_by?.system_owners?.value
            ?.map((item) =>
              item?.user_type === "USR"
                ? `${item?.system_owner_name || ""} (${
                    item?.system_owner_email || ""
                  })`
                : item?.system_owner_name
            )
            ?.filter((item) => item && item?.trim() !== "()"),
          apiKey: "get_user_users_groups",
          urlParam: ["", "USR,UGP"],
          isInherited: data?.managed_by?.system_owners?.value?.some(
            (item) => item?.is_inherited
          ),
          inHeritedBy:
            data?.managed_by?.system_owners?.value?.find(
              (item) => item?.is_inherited
            )?.inherited_from || "",
        },
        {
          title: DATA_SOURCE_SECTION_TITLES?.managed_by?.data_stewards,
          keyValueTags: data?.managed_by?.data_stewards?.value
            ?.map((item) => ({
              name:
                item?.user_type === "USR"
                  ? `${item?.data_steward_name || ""} (${
                      item?.data_steward_email || ""
                    })`
                  : item?.data_steward_name,
              type: item?.user_type,
              email: item?.data_steward_email || "",
            }))
            ?.filter((item) => item?.name && item?.name?.trim() !== "()"),
          type: "multiselect",
          isEditable: true,
          selectedValues: data?.managed_by?.data_stewards?.value
            ?.map((item) =>
              item?.user_type === "USR"
                ? `${item?.data_steward_name || ""} (${
                    item?.data_steward_email || ""
                  })`
                : item?.data_steward_name
            )
            ?.filter((item) => item && item?.trim() !== "()"),
          apiKey: "get_user_users_groups",
          urlParam: ["", "USR,UGP"],
          isInherited: data?.managed_by?.data_stewards?.value?.some(
            (item) => item?.is_inherited
          ),
          inHeritedBy:
            data?.managed_by?.data_stewards?.value?.find(
              (item) => item?.is_inherited
            )?.inherited_from || "",
          isDataStewardField: true,
        },
        {
          title: DATA_SOURCE_SECTION_TITLES?.managed_by?.audit_trail,
          value: `${createdObj?.activity || ""} by ${
            createdObj?.user_name || ""
          } ${getTimeDifference(
            utcTOLocalTimeZone(createdObj?.activity_on || "")
          )}:${updatedObj?.activity || ""} by ${
            updatedObj?.user_name || ""
          } ${getTimeDifference(
            utcTOLocalTimeZone(updatedObj?.activity_on || "")
          )}`,
          isEditable: true,
        },
      ],
    },
    description: {
      data: [
        {
          title: DATA_SOURCE_SECTION_TITLES?.overview?.description,
          value: data?.description?.description.value || "",
          isEditable: true,
          type: "textarea",
        },
        {
          title: DATA_SOURCE_SECTION_TITLES?.overview?.title,
          value: data?.title?.value || "",
          isRowVisibleInNormalView: false,
          isEditable: true,
          type: "input",
        },
        {
          title: DATA_SOURCE_SECTION_TITLES?.overview?.last_refreshed_on,
          value: formatUtcToLocalDate(
            data?.last_refreshed_on || "",
            MONTH_DAY_YEAR_HOUR_MINUTE
          ),
          isRowVisibleInEditView: false,
        },
        {
          title: DATA_SOURCE_SECTION_TITLES?.overview?.image_preview,
          value: data?.node_image_url || "",
          isRowVisibleInEditView: false,
          isRowVisibleInNormalView: !!(isReportType && data?.node_image_url),
        },
        {
          title: DATA_SOURCE_SECTION_TITLES?.classification?.functional_area,
          value: functionalAreaName,
          selectedValues: functionalAreaName
            ? [`${functionalAreaId}`]
            : undefined,
          type: "select",
          isEditable: true,
        },
        {
          title: DATA_SOURCE_SECTION_TITLES?.classification?.entity,
          value: entityTitle,
          selectedValues: [`${entityId}`],
          isEditable: true,
          type: "select",
        },
      ],
      isEdited: false,
      editable: false,
    },
    publish_status_id: data?.published_status_id,
    publish_status: data?.published_status,
    is_wf_enabled: data?.is_wf_enabled || false,
    source_id: data?.parent_id || 0,
    src_type_id: data?.src_type_id,
    db_name: data?.db_name || "None",
    schema_name: data?.folder_name || "None",
  };
}

export function getParsedGlossaryCategoriesForSelection({
  data,
}: AxiosResponse<
  GlossaryCategoriesResponse[]
>): ReturnTypeOfGetParsedGlossaryCategoriesForSelection {
  return data?.map((item) => ({
    key: `${item?.CAT_ID || ""}`,
    value: `${item?.CAT_ID || ""}`,
    label: item?.CAT_NAME || "",
    labelText: item?.CAT_NAME || "",
    domainName: item?.DMN_NAME || "",
  }));
}

export function getParsedReportPages({
  data,
}: AxiosResponse<WorkSheetsResponse[]>): RetunTypeOfReportPagesParser {
  const sheetsJson = jsonParse(data?.[0]?.BO_WORKSHEETS, true) as WorkSheets;
  const keys = getObjectKeys(sheetsJson);

  return keys?.map((sheet) => {
    const currentObj = sheetsJson?.[sheet];
    return {
      sheet_desc: currentObj?.sheet_desc || "--",
      sheet_id: currentObj?.sheet_id || "",
      sheet_name: currentObj?.sheet_name || "",
      sheet_url: currentObj?.sheet_url || data?.[0]?.BO_URL || "",
      sheet_image_url: currentObj?.sheet_image_url || "",
    };
  });
}

export function getParsedReportFields({
  data,
}: AxiosResponse<ReportFieldsResponse[]>): ReturnTypeOfParsedReportFields[] {
  return (
    data?.map((field) => {
      const connectionFieldsJson = jsonParse(
        field?.DATA_CONNECTION_FIELDS,
        false
      ) as DataConnectionFields;

      const pagesJson = jsonParse(field?.PAGES, true) as ReportFieldsPagesType;
      const pagesKeys = getObjectKeys(pagesJson);

      return {
        data_connection_field: connectionFieldsJson?.map((item) => ({
          id: `${item?.col_id || ""}`,
          name: item?.col_name || "",
        })),
        data_source_field: field?.COL_TITLE || field?.COL_NAME || "",
        data_source_field_id: `${field?.COL_ID || ""}`,
        data_type: field?.COL_DATA_TYPE_ID,
        desc: field?.COL_USER_DESC || "",
        entity: field?.COL_ENTITY_NAME || "",
        entity_id: `${field?.COL_ENTITY_ID || ""}`,
        field_type: field?.COL_TYPE,
        pages_name: pagesKeys?.map((page) => page || "") || [],
        data_type_title: field?.COL_DATA_TYPE || "",
      };
    }) || []
  );
}

export const getParsedSimilarNodes = ({
  data,
}: AxiosResponse<SimilarNodesResponse[]>): SimilarNodesParserReturnType[] => {
  return (
    data?.map((item) => {
      const tags = jsonParse(item?.JSONB_AGG) as ProminentTagsSimilarNodes[];
      const tagsSummary =
        tags?.map((tag) => ({
          id: `${tag?.tag_id || 0}`,
          name: tag?.tag_name || "",
          type: tag?.tag_color || "GRN",
          tagDetails: {
            tagName: tag?.tag_name || "",
            tagsetId: `${tag?.tagset_id || 0}`,
            tagsetName: tag?.tagset_name || "",
            tagNote: tag?.tag_comments || "",
            tagsetDesc: tag?.tag_desc || "",
            showDetailInfo: true,
            updatedBy: tag?.tag_created_by || "",
            updatedOn: tag?.tag_created_on,
          },
        })) || [];

      return {
        header: [{ title: item?.SRC_NAME, nodeType: "SRC" }],
        breadcrumb: [
          {
            title: item?.SRC_NAME || "",
            type: "src",
            srcTypeId: `${item?.SRC_TYPE || "ORC"}`,
            nodeId: `${item?.SRC_ID || ""}`,
          },
        ],
        desc: item?.BO_USER_DESC || "",
        url: item?.BO_URL || "",
        title: {
          nodeType: "DSR",
          nodeSubType: item?.BO_TYPE as NodeSubType,
          mainTitle: item?.BO_DISPLAY_NAME || "",
          subTitle: "",
          tags: tagsSummary,
          status: { id: item?.BO_STATUS_ID, value: item?.BO_STATUS },
        },
        additionalInfo: [
          {
            title: "Data Domain",
            value: item?.FUNC_AREA_NAME || "",
          },
          {
            title: "Last Refreshed On",
            value: formatUtcToLocalDate(item?.BO_LAST_REFRESHED_ON, "Pp"),
          },
        ],
        isHideFooter: true,
        nodeId: `${item?.BO_ID || 0}`,
      };
    }) || []
  );
};
