import {
  GovernanceViewFieldsParserReturnType,
  ReturnTypeOfRefParser,
} from "../../../../../../parsers";
import { DashboardFiltersParsedType } from "../../../../../../parsers/dashboardparser/dashboardparser.types";
import { eyeIcon, rightAngleIcon } from "../../../../../../svgs";
import Flex from "../../../../../flex";
import { SelectFieldOption } from "../../../../../formfields/selectfield/selectfield.types";
import LinkButton from "../../../../../linkbutton/linkbutton";
import { FilterNameAndDescWrapperStyled } from "./addwidgetform.styles";
import { SubOptionsMappingType } from "./addwidgetform.types";
import {
  getSubOptionsInfoMapping,
  getSubOptionsMapping,
} from "./addwidgetform.utils";

export const getOptionsForSelectWithoutSubMenu = (
  columns: GovernanceViewFieldsParserReturnType[],
  watchFields: string[],
  isAllowedNumberOfFieldsAreSelected: boolean
): SelectFieldOption[] => {
  const selectOptions =
    columns?.map((item) => {
      const optionId = String(item?.field_id);
      const isSelectedValue = watchFields?.includes(optionId);

      return {
        key: `${item?.field_id}`,
        value: optionId,
        label: item?.field_display_name,
        disabled: isAllowedNumberOfFieldsAreSelected && !isSelectedValue,
      };
    }) || [];

  return selectOptions;
};

export const getOptionsForSelectWithSubMenu = (
  columns: GovernanceViewFieldsParserReturnType[],
  watchFields: string[],
  isAllowedNumberOfFieldsAreSelected: boolean,
  disableOption?: string[]
): SelectFieldOption[] => {
  const selectOptions =
    columns?.map((item) => {
      const { field_display_name = "", field_id = "" } = item || {};
      const optionId = String(item?.field_id);
      const isSelectedValue = watchFields?.includes(optionId);

      const isCountMetric = optionId === "CNT";

      const isDisableOption = disableOption?.includes(optionId);

      return {
        label: isCountMetric ? (
          "Count"
        ) : (
          <Flex justifyContent="space-between" alignItems="center">
            <span>{field_display_name}</span>
            <span>{rightAngleIcon}</span>
          </Flex>
        ),
        value: optionId,
        labelText: field_display_name,
        isSubMenuExist: !isCountMetric,
        disabled:
          (isAllowedNumberOfFieldsAreSelected && !isSelectedValue) ||
          isDisableOption,
      };
    }) || [];

  return selectOptions;
};

export const getFiltersSelectOptions = (
  filters: DashboardFiltersParsedType[],
  onClick: (filter: DashboardFiltersParsedType) => void
): SelectFieldOption[] => {
  const selectOptions =
    filters?.map((filter) => {
      const {
        filterName = "",
        filterDesc = "",
        filterId = "",
        filterSql = "",
      } = filter || {};

      return {
        label: (
          <FilterNameAndDescWrapperStyled>
            <div className="name-desc-wrapper">
              <span className="name">{filterName}</span>
              {filterDesc && <span className="desc">({filterDesc})</span>}
            </div>
            <LinkButton
              className="module-filter-criteria-eye-icon"
              onClick={(e): void => {
                e.stopPropagation();
                onClick(filter);
              }}
            >
              {eyeIcon("15", "12")}
            </LinkButton>
          </FilterNameAndDescWrapperStyled>
        ),
        title: filterName,
        value: filterId,
        key: `${filterId}-${filterName}`,
        labelDesc: (filterDesc || filterSql) && (
          <Flex direction="column" rowGap={5}>
            {filterDesc && <span>{filterDesc}</span>}
            {filterSql && <span>{filterSql}</span>}
          </Flex>
        ),
        textForSearch: filterName,
      };
    }) || [];

  return selectOptions;
};

export const getSelectSubOptions = (
  columns: GovernanceViewFieldsParserReturnType[],
  refs: ReturnTypeOfRefParser[],
  isMetricField?: boolean
): SubOptionsMappingType => {
  const selectOptions = columns?.reduce((acc, item) => {
    const { field_id = "" } = item || {};

    const isCountMetric = field_id === "CNT";

    const key = String(field_id) || "";

    const optionsArray = getSubOptionsMapping(item, refs);

    const optionsInfoArray = getSubOptionsInfoMapping(
      item,
      refs,
      isMetricField
    );

    acc[key] = {
      options: optionsArray,
      optionsInfo: isCountMetric ? [] : optionsInfoArray,
    };

    return acc;
  }, {} as SubOptionsMappingType);

  return selectOptions;
};
