import { WidgetTemplateType } from "../../../../../../contexts/widgetcreationcontext/widgetcreationcontext.types";
import { filterCriteriaApiData } from "../../../../../../forms/addgovernanceviewform/addgovernanceviewform.utils";
import {
  ColumnDataType,
  GovernanceViewFieldsParserReturnType,
  ReturnTypeOfRefParser,
} from "../../../../../../parsers";
import {
  isJsonString,
  isNumber,
  jsonParse,
  sortListOnSpecificKeyValue,
} from "../../../../../../utils";
import { SelectFieldOption } from "../../../../../formfields/selectfield/selectfield.types";
import { TranferStateType } from "../../../../../transfer/transfer.types";
import {
  AddWidgetFormApiDataType,
  AddWidgetFormType,
  CombinedRefsType,
  CurrentItemType,
  OptionsInfoType,
  RefsInfoType,
  SelectedWidgetParsedType,
  SelectedWidgetTemplateType,
  WidgetFieldType,
} from "./addwidgetform.types";

export const COUNT_METRIC = {
  field_id: "CNT",
  field_id_field: "CNT",
  field_display_name: "Count",
  field_name: "Count",
  field_datatype: "STR" as ColumnDataType,
  display_order: 0,
  is_attribute: false,
  delimiter: "",
  is_field_hidden: false,
  field_type_id: "STR" as ColumnDataType,
  field_distinct_count: 0,
  is_field_filterable: true,
  is_metric: true,
  is_attribute_as_metric: false,
};

export const WIDGET_FILTER_CRITERIA_ID = "widget-filter";

export const existingFilters = [
  {
    name: "Sources of march",
    desc: "Created on is Greater than and equal to",
    value: "EX-1",
  },
  {
    name: "Region",
    desc: 'Country includes "US","UK","Canada"',
    value: "EX-2",
  },
  {
    name: "Customer Filter",
    desc: "Customer age greater than equal to 5",
    value: "EX-3",
  },
];
export const existingViews = [
  {
    name: "All Tables",
    desc: "",
    value: "EXV-1",
  },
  {
    name: "All Columns",
    desc: "",
    value: "EXV-2",
  },
  {
    name: "Data Check",
    desc: "source Include snowflake",
    value: "EXV-3",
  },
];

export const arregrateRef = [
  {
    name: "Sum",
    id: "1",
  },
  {
    name: "Average",
    id: "2",
  },
  {
    name: "Min",
    id: "3",
  },
  {
    name: "Max",
    id: "4",
  },
];

export const getSubOptionsMapping = (
  currentItem: GovernanceViewFieldsParserReturnType,
  refsInfo: RefsInfoType
): SelectFieldOption[] => {
  const { field_id = "", is_attribute_as_metric } = currentItem;

  const onlyCountRefs = refsInfo?.filter(
    (item) => item?.id === "CNT" || item?.id?.trim() === "CD"
  );

  const exlcudeCountRefs = refsInfo?.filter(
    (item) => item?.id !== "CNT" && item?.id?.trim() !== "CD"
  );

  const sourceRefs = is_attribute_as_metric ? onlyCountRefs : exlcudeCountRefs;

  return [
    ...(sourceRefs?.reduce<SelectFieldOption[]>((acc, item) => {
      acc?.push({
        key: `${field_id}-${item?.id}`,
        value: `${field_id}-${item?.id}`,
        label: item?.name || "",
      });

      return acc;
    }, []) || []),
  ];
};

export const getSubOptionsInfoMapping = (
  currentItem: CurrentItemType,
  refsInfo: RefsInfoType,
  isMetricField?: boolean,
  attributeColumns?: GovernanceViewFieldsParserReturnType[]
): OptionsInfoType[] => {
  const { field_display_name = "", field_id = "" } = currentItem;

  const sortedRefInfo = sortListOnSpecificKeyValue({
    list: refsInfo,
    key: "name",
  });

  const subOptionsInfoArray =
    sortedRefInfo?.map((date) => {
      const trimId = date?.id?.trim();
      return {
        key: `${field_id}-${trimId}`,
        parentName: field_display_name || "",
        parentId: `${field_id}`,
        optionId: `${trimId}`,
        optionName: date?.name || "",
        optionDesc: isMetricField
          ? `${date?.name} On ${field_display_name}`
          : `${field_display_name} - ${date?.name}`,
        hasSubPanel: false,
      };
    }) || [];

  return [
    ...subOptionsInfoArray,
    // this is to get parent config
    {
      key: `${field_id}`,
      hasSubPanel: true,
      parentName: field_display_name,
      parentId: field_id,
      optionId: "",
      optionDesc: field_display_name,
    },
  ];
};

export const combineRefsIdsAndName = (
  allRefs: ReturnTypeOfRefParser[]
): CombinedRefsType => {
  return (
    allRefs?.reduce((acc, item) => {
      const trimmedId = item?.id?.trim();
      if (trimmedId && item?.name) {
        acc[trimmedId] = item?.name;
      }
      return acc;
    }, {} as CombinedRefsType) || {}
  );
};

export const getSortValuesArray = (selectedValues: string[]): string[] => {
  const seenBaseValues = new Set();

  return selectedValues?.reduce<string[]>((filtered, value) => {
    const base = value?.split("-")?.[0];

    // If the base value has not been seen, add the current value to the result
    if (!seenBaseValues?.has(base)) {
      filtered?.push(value);
      seenBaseValues?.add(base);
    } else {
      // If the base value has been seen, remove the base value and add the derived value
      filtered = filtered?.filter((item) => item !== base);
      filtered?.push(value);
    }

    return filtered;
  }, []);
};

export const getBaseValue = (value: string): string => {
  return value?.split("-")?.[0]?.trim() || "";
};

export const getChildValue = (value: string): string => {
  return value?.split("-")?.[1]?.trim() || "";
};

export const getSortOptions = (
  selectedSlicerOrMetricValues: string[],
  allSlicerAndMetricFields: GovernanceViewFieldsParserReturnType[],
  allRefsNameAndIds: CombinedRefsType,
  isMetric?: boolean
): SelectFieldOption[] => {
  const sortOptionsField = selectedSlicerOrMetricValues?.map((item) => {
    const baseMetricField = getBaseValue(item);
    const childMetricFiled = getChildValue(item);

    // find parent that is being used from all columns that exists
    const fieldBeingUsed = allSlicerAndMetricFields?.find(
      (field) => String(field?.field_id) === String(baseMetricField)
    );

    // check if its 113-DLY or only 113
    const isItemBothBaseAndChildMetric = item?.includes("-");

    // get the title of DLY in 113-DLY
    const getTitle = allRefsNameAndIds?.[childMetricFiled];

    return {
      label: isItemBothBaseAndChildMetric
        ? isMetric
          ? `${getTitle} On ${fieldBeingUsed?.field_display_name}`
          : `${fieldBeingUsed?.field_display_name} - ${getTitle}`
        : `${fieldBeingUsed?.field_display_name}`,
      value: item ? String(item) : "",
    };
  });

  return sortOptionsField || [];
};

export const getSlicerValuesFromApi = (
  slicerValues: WidgetFieldType
): string => {
  const { field_id, field_grain } = slicerValues;
  return field_grain ? `${field_id}-${field_grain}` : String(field_id);
};

export const getMetricValuesFromApi = (
  metricValues: WidgetFieldType
): string => {
  const { field_id, field_agg_by } = metricValues;

  return field_id && field_agg_by
    ? `${field_id}-${field_agg_by}`
    : field_id
    ? String(field_id)
    : "CNT";
};

export const createWidgetApiData = (
  formValues: AddWidgetFormType,
  parsedFields: GovernanceViewFieldsParserReturnType[],
  allRefsNameAndIds: CombinedRefsType,
  transferState: TranferStateType
): AddWidgetFormApiDataType => {
  const {
    title = "",
    chart_type,
    module,
    slicer = [],
    metric = [],
    sort_by_property = "ASC",
    sort_by,
    enable_data_labels = false,
    widget_filter_criteria_name,
    is_template_filter,
    widget_filter_criteria_filters,
    order_of_widget,
    is_full_screen,
  } = formValues || {};

  const baseFields = transferState?.targetKeys;
  const baseSlicerFields = getSortValuesArray(slicer);
  const baseMetricFields = getSortValuesArray(metric);
  const baseSortedFields = getBaseValue(sort_by_property);

  const fields =
    baseFields?.map((slicerItem, index) => {
      const baseItem = getBaseValue(slicerItem);
      const grainItem = getChildValue(slicerItem);

      const seleectedField = parsedFields?.find(
        (item) => Number(item?.field_id) === Number(baseItem)
      );

      return {
        field_id: Number(baseItem),
        field_data_type: seleectedField?.field_datatype || "STR",
        field_grain: grainItem || null,
        field_display_name: seleectedField?.field_display_name || "",
        field_name: seleectedField?.field_name || "",
        display_order: index,
      };
    }) || [];

  const slicerFields =
    baseSlicerFields?.map((slicerItem) => {
      const baseItem = getBaseValue(slicerItem);
      const grainItem = getChildValue(slicerItem);

      const seleectedField = parsedFields?.find(
        (item) => Number(item?.field_id) === Number(baseItem)
      );

      return {
        field_id: Number(baseItem),
        field_data_type: seleectedField?.field_datatype || "STR",
        field_grain: grainItem || null,
        field_name: seleectedField?.field_display_name || "",
      };
    }) || [];

  const metricFields =
    baseMetricFields?.map((metricItem) => {
      const baseItem = getBaseValue(metricItem);
      const aggregratedItem = getChildValue(metricItem);

      const trimmedAggregratedItem = aggregratedItem?.trim();

      const seleectedField = parsedFields?.find(
        (item) => Number(item?.field_id) === Number(baseItem)
      );

      const isCountMetricSelected = baseItem === "CNT";

      const titleOfAggregrate = allRefsNameAndIds?.[trimmedAggregratedItem];

      return {
        field_id: isCountMetricSelected ? null : Number(baseItem),
        field_data_type: seleectedField?.field_datatype || "STR",
        field_agg_by: isCountMetricSelected ? "CNT" : trimmedAggregratedItem,
        field_name: isCountMetricSelected
          ? "Count"
          : seleectedField?.field_display_name || "",
        field_agg_by_name: titleOfAggregrate,
      };
    }) || [];

  const formFilterCondition = widget_filter_criteria_filters || [];

  const filterCondition =
    filterCriteriaApiData(formFilterCondition, parsedFields) || [];

  const updatedFields = [
    ...fields,
    ...parsedFields
      ?.filter(
        (item) =>
          item?.is_field_hidden && !baseFields?.includes(`${item?.field_id}`)
      )
      ?.map((val) => ({
        field_id: Number(val?.field_id),
        field_data_type: val?.field_datatype || "STR",
        field_grain: null,
        field_display_name: val?.field_display_name || "",
        field_name: val?.field_name || "",
        // display_order: index,
      })),
  ];

  return {
    widget_name: title,
    widget_chart_type: chart_type,
    widget_view_category: module,
    widget_config: {
      fields: [
        ...updatedFields,
        // ...fields,
        // ...parsedFields
        //   ?.filter((item) => item?.is_field_hidden)
        //   ?.map((val) => ({
        //     field_id: Number(val?.field_id),
        //     field_data_type: val?.field_datatype || "STR",
        //     field_grain: null,
        //     field_display_name: val?.field_display_name || "",
        //     field_name: val?.field_name || "",
        //     // display_order: index,
        //   })),
      ],
      slicer_fields: slicerFields,
      metric_fields: metricFields,
      sort_field_id: isNumber(baseSortedFields)
        ? Number(baseSortedFields)
        : baseSortedFields,
      sort_type: baseSortedFields ? sort_by : null,
      is_full_screen,
      enable_datalabels: enable_data_labels,
    },
    widget_display_order: order_of_widget,
    widget_filter_name: widget_filter_criteria_name,
    is_template_filter: is_template_filter || false,
    widget_filter_json: formFilterCondition?.length
      ? {
          filter: filterCondition,
        }
      : {},
  };
};

export const selectedWidgetTemplateTransformer = (
  widget: WidgetTemplateType,
  widgetOrder: number
): SelectedWidgetParsedType => {
  const { name: widgetName = "", adhocInfo, order, id: widgetId } =
    widget || {};

  const parsedSelectedWidgetAdhocInfo = isJsonString(adhocInfo || "")
    ? jsonParse(adhocInfo || "")
    : {};

  const {
    chart_type: widgetChartType,
    gov_view_category: govViewCategory,
    widget_config: selectedWidgetConfig,
  } = (parsedSelectedWidgetAdhocInfo as SelectedWidgetTemplateType) || {};

  const {
    enable_datalabels: enableDataLabels,
    sort_type: sortType,
    metric_fields: metricFields,
    slicer_fields: slicerFields,
  } = selectedWidgetConfig || {};

  return {
    widgetName,
    widgetOrder,
    widgetChartType,
    govViewCategory,
    enableDataLabels,
    sortType,
    metricFields,
    slicerFields,
    widgetId: Number(widgetId),
    isWidgetFullScreen: false,
  };
};
