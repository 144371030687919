import { CheckboxChangeEvent } from "antd/lib/checkbox";
import { useCallback } from "react";

import { Col, Row } from "antd";

import { DefinitionTabType } from "./definitiontab.types";

import { AnalysisDetailPageExplorerSec } from "../../analysisdetailpage.views/analysisdetailpagecontentsec/analysisdetailpagecontentsec.views";
import {
  ChatBotDetailStyled,
  CheckboxWrapperStyled,
  DefinitionFormStyled,
  DefinitionTabStyled,
  HeadingStyled,
  TableNameWithNodeIcon,
} from "./definitiontab.styles";

import CoreNodesCircleIcon from "../../../../components/corenodetypescircleicon/corenodetypescircleicon";
import LinkButton from "../../../../components/linkbutton/linkbutton";
import { useOpenDrawer } from "../../../../customhooks";
import Flex from "../../../../components/flex/flex";
import { CheckboxStyled } from "../../../../components/checkbox/checkbox.styles";
import { ConfigurationView, AgentConfigView } from "./definitiontab.views";
import { ChatAgentField } from "./definitiontab.components";

const DefinitionTab = (props: DefinitionTabType): JSX.Element => {
  const {
    analysisSrcTypeId,
    analysisNodeSubType,
    isEditMode,
    pinnedTablesList,
    isScopeEnabled,
    configuration,
    onScopeEnabledClick,
    enableSaveButton,
    disableSaveButton,
    updateConfiguration,
    aiChatModel = "",
    aiChatModelName = "",
    onChangeAIChatModel,
    setIsFormValid,
  } = props;

  const openDrawer = useOpenDrawer();

  const onOpenRef = useCallback((id: number) => {
    openDrawer({
      drawerId: "table_ref",
      visible: true,
      drawerProps: { id },
    });
  }, []);

  const pinnedTables = pinnedTablesList?.length || 0;

  const isRestDataSourceTypeAnalysis = analysisSrcTypeId === "RST";
  const isTool = analysisNodeSubType === "TOL";

  return (
    <DefinitionTabStyled>
      {isRestDataSourceTypeAnalysis && (
        <>
          {isTool ? (
            <ConfigurationView
              isEditMode={isEditMode}
              configuration={configuration}
              updateConfiguration={updateConfiguration}
              key={`configuration-view-${isEditMode ? "editmode" : "viewmode"}`}
              aiChatModel={aiChatModel}
              aiChatModelName={aiChatModelName}
              onChangeAIChatModel={onChangeAIChatModel}
            />
          ) : (
            <AgentConfigView {...props} />
          )}
        </>
      )}

      {!isRestDataSourceTypeAnalysis && (
        <>
          <div className="col">
            {/* TODO: refactor this block of code to use the reuseable UI component for applying limit */}
            <HeadingStyled>Tables</HeadingStyled>
            {isEditMode ? (
              <Row className="chat-bot-wrapper">
                <Col>
                  <DefinitionFormStyled>
                    <CheckboxWrapperStyled flexDirection="row">
                      <CheckboxStyled
                        checked={isScopeEnabled}
                        onChange={(event: CheckboxChangeEvent): void => {
                          const isChecked = event?.target?.checked;
                          !isChecked && enableSaveButton();
                          if (isChecked && !pinnedTablesList?.length)
                            disableSaveButton?.();
                          onScopeEnabledClick();
                        }}
                      >
                        <span className="label"> Limit to specific tables</span>
                      </CheckboxStyled>
                    </CheckboxWrapperStyled>

                    <p className="checkbox-desc">
                      By selecting this checkbox, you can limit the tables that
                      can be chatted by this Agent. This could be useful, where
                      you want to restrict the tables from a large data source
                      to localize for certain tools that would be assigned to
                      certain roles.
                    </p>

                    {isScopeEnabled && (
                      <>
                        <p className="select-tables">Select Tables</p>
                        <span className="select-tables-desc">
                          Select the tables you wish to engage in a chat with
                          using this Agent. If you want to control which columns
                          are allowed for chat click on the Manage Fields button
                          on the right.
                        </span>
                        <div className="chat-bot-explorer-sec">
                          <AnalysisDetailPageExplorerSec {...props} />
                        </div>
                      </>
                    )}
                  </DefinitionFormStyled>
                </Col>
              </Row>
            ) : (
              <Row className="chat-bot-details">
                <ChatBotDetailStyled>
                  <p className="label">Limit to specific tables:</p>

                  {pinnedTables ? (
                    <div className="table-listing">
                      <p className="table-count">
                        {pinnedTables} table
                        {pinnedTables > 1 ? "s have" : " has"} been selected
                      </p>
                      <Flex
                        direction="row"
                        columnGap={5}
                        alignItems="flex-start"
                      >
                        <Flex
                          customClass="tables-list"
                          direction="column"
                          alignItems="flex-start"
                        >
                          {!!pinnedTables &&
                            pinnedTablesList?.map((table, index) => {
                              return (
                                <Flex
                                  alignItems="flex-end"
                                  justifyContent="flex-end"
                                  columnGap={5}
                                  key={`pinned-tables-${table?.id}`}
                                  customClass="pinned-tables"
                                >
                                  <div
                                    key={`definition-tables-bullets-${index}`}
                                    className="table-bullets"
                                  />
                                  <TableNameWithNodeIcon
                                    key={`definition-tables-sec-${table?.id}`}
                                    className="definition-tables-bullets"
                                  >
                                    <CoreNodesCircleIcon
                                      nodeType="TBL"
                                      height="19.2px"
                                      width="19.2px"
                                      fontSize="12"
                                    />
                                    <LinkButton
                                      onClick={(): void => onOpenRef(table?.id)}
                                      className="table-name"
                                    >
                                      {table?.title
                                        ?.split(".")
                                        ?.slice(-1)?.[0] || table?.title}
                                    </LinkButton>
                                  </TableNameWithNodeIcon>
                                </Flex>
                              );
                            })}
                        </Flex>
                      </Flex>
                    </div>
                  ) : (
                    <span className="empty-text">No Limit Applied</span>
                  )}
                </ChatBotDetailStyled>
              </Row>
            )}
          </div>

          <div className="col">
            <HeadingStyled marginBottom="10px">Configuration</HeadingStyled>
            <ChatAgentField
              isEditMode={isEditMode}
              aiChatModel={aiChatModel}
              aiChatModelName={aiChatModelName}
              onChangeAIChatModel={onChangeAIChatModel}
              setIsFormValid={setIsFormValid}
            />
          </div>
        </>
      )}
    </DefinitionTabStyled>
  );
};

export default DefinitionTab;
