import {
  LineagePageQueryNodeParamsType,
  LineagePageUrlItemsType,
  LineageUrlNodesType,
} from "../pages/lineagepage/lineagepage.types";
import { convertNodeNameToSafeName } from "../pages/lineagepage/lineagepage.utils/helper";

import { getLineageUrl } from "./getlineageurl";
import { jsonStringify } from "./jsonstringify";

export const buildLineagePageUrl = (
  urlItems: LineagePageUrlItemsType
): string => {
  const {
    nodeId = "",
    nodeName = "",
    nodeType,
    nodeSubType,
    isSubNode = false,
  } = urlItems || {};

  const stringifyNodeId = nodeId?.toString();

  const lineageNode: LineagePageQueryNodeParamsType = {
    isFocused: true,
    nodeId: stringifyNodeId,
    nodeType,
    nodeName: convertNodeNameToSafeName(nodeName),
    nodeLevel: isSubNode ? 2 : 1,
    isBaseNode: true,
    isExpanded: false,
    oneUpstream: "show",
    oneDownstream: "show",
  };

  const finalNodesObj: LineageUrlNodesType = {
    [stringifyNodeId]: lineageNode,
  };

  const allNodes = jsonStringify(finalNodesObj);

  const lineageUrl = getLineageUrl({
    originNodeId: stringifyNodeId,
    originNodeName: convertNodeNameToSafeName(nodeName),
    originNodeType: nodeType,
    originNodeSubType: nodeSubType,
    nodes: allNodes,
    lineageLevel: isSubNode ? "column" : "table",
    lineageType: "functional",
    mode: "",
    enabledLayer: "",
  });

  return lineageUrl;
};
