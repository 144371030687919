import { useCallback } from "react";
import { useHistory } from "react-router";
import { SectionRowProps } from "../../components/sectionrow";

import {
  convertToHoursMinutesAndSeconds,
  getTableDetailPageUrl,
  getTimeDifference,
} from "../../utils";

import { useOpenDrawer } from "../../customhooks";
import { NodeType } from "../../app.types";

import { CoreNodesCircleIcon } from "../../components";

import StyledLink from "../../components/styledlink/styledlink";

import Flex from "../../components/flex";
import {
  getEtlRunStatusIcon,
  getEtlRunStatusText,
} from "../../utils/getetlstatus";
import {
  MultipleNodesRendererStyled,
  StatusRendererStyled,
} from "./etlrefdrawer.styles";
import LinkButton from "../../components/linkbutton";
import { tableDetailPageTabsValues } from "../../pages/tablepage/tablepage.constants";
import { ETL_SECTION_TITLES } from "../../constants/labelconstants/etlconfig";
import { settingsIcon } from "../../svgs";

const {
  META_INFO: { SOURCE, TARGET, ETL_PARENT_NODE },
  EXECUTION_DETAILS: { LAST_RUN, DURATION, RUN_STATUS },
} = ETL_SECTION_TITLES;

const NodeRenderer = (item: SectionRowProps): JSX.Element => {
  const { value } = item || {};

  const etlNodeIcon = settingsIcon;

  return value ? (
    <Flex columnGap={5}>
      <span className="etl-node-icon">{etlNodeIcon}</span>
      <div className="etl-parent-name">{value}</div>
    </Flex>
  ) : (
    <div>--</div>
  );
};

const LastRunRenderer = (item: SectionRowProps): JSX.Element => {
  const { value = "" } = item || {};
  return <div>{value ? getTimeDifference(value) : "--"}</div>;
};

const DurationRenderer = (item: SectionRowProps): JSX.Element => {
  const { value = "" } = item || {};
  return (
    <div>{value ? convertToHoursMinutesAndSeconds(Number(value)) : "--"}</div>
  );
};

const StatusRenderer = (item: SectionRowProps): JSX.Element => {
  const { id = "" } = item || {};

  const value = getEtlRunStatusText(id);
  const valueLowerCase = value?.toLocaleLowerCase();

  return (
    <StatusRendererStyled>
      {id ? (
        <>
          <div className={`${valueLowerCase}`}>{getEtlRunStatusIcon(id)}</div>
          <div>{value}</div>
        </>
      ) : (
        "--"
      )}
    </StatusRendererStyled>
  );
};

const MultipleNodeRenderer = (
  item: SectionRowProps & { nodeType: NodeType }
): JSX.Element => {
  const openDrawer = useOpenDrawer();

  const getNodeUrl = useCallback((nodeId) => {
    const tblUrl = getTableDetailPageUrl(
      nodeId,
      tableDetailPageTabsValues.overview
    );

    return tblUrl || "";
  }, []);

  return (
    <MultipleNodesRendererStyled>
      {item?.stringValues?.map((field, index) => {
        return (
          <div className="table-field-name" key={`field-${field}`}>
            <CoreNodesCircleIcon width="20px" height="20px" nodeType="TBL" />
            <LinkButton>
              <StyledLink to={getNodeUrl(item?.ids?.[index])}>
                {field}
              </StyledLink>
            </LinkButton>
          </div>
        );
      })}
    </MultipleNodesRendererStyled>
  );
};

export const etlRefDrawer: {
  [key: string]: (_item: SectionRowProps) => JSX.Element;
} = {
  [TARGET]: (item) => <MultipleNodeRenderer {...item} nodeType="TBL" />,
  [SOURCE]: (item) => <MultipleNodeRenderer {...item} nodeType="TBL" />,
  [LAST_RUN]: (item) => <LastRunRenderer {...item} />,
  [DURATION]: (item) => <DurationRenderer {...item} />,
  [RUN_STATUS]: (item) => <StatusRenderer {...item} />,
  [ETL_PARENT_NODE]: (item) => <NodeRenderer {...item} />,
};
